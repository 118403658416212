import * as Yup from 'yup';

export const getCalculatorSchema = (
  minReceivingAmount: number,
  maxReceivingAmount: number,
  minSendingAmount: number,
  maxSendingAmount: number,
  fractionalReceivingAmountUsage: 'enabled' | 'disabled' | 'allowed' | undefined
) => Yup.object().shape({
  receivingCountry: Yup.mixed().required('error.validation.required'),
  sendingCountry: Yup.mixed().required('error.validation.required'),
  receivingCurrency: Yup.mixed().required('error.validation.required'),
  sendingCurrency: Yup.mixed().required('error.validation.required'),
  receivingAmount: Yup.number()
    .test(
      'tariffNotFound',
      'common.errors.validation.tariff.cantFindTariff',
      () => !Number.isNaN(minReceivingAmount) && !Number.isNaN(maxReceivingAmount)
    )
    .max(maxReceivingAmount, 'error.validation.maxReceivingAmount')
    .min(minReceivingAmount, 'error.validation.minReceivingAmount')
    .required('error.validation.required'),
  ...((!fractionalReceivingAmountUsage || fractionalReceivingAmountUsage === 'enabled') && {
    sendingAmount: Yup.number()
      .test(
        'tariffNotFound',
        'common.errors.validation.tariff.cantFindTariff',
        () => !Number.isNaN(minSendingAmount) && !Number.isNaN(maxSendingAmount)
      )
      .max(maxSendingAmount, 'error.validation.maxSendingAmount')
      .min(minSendingAmount, 'error.validation.minSendingAmount')
      .required('error.validation.required')
  })
});
