import { resolveNewInfoItem } from '@utils/helpers/calculator/resolveNewInfoItem';
import { convertToMoney, multiplyByHundred, validateAmountLimit } from '@utils/helpers';
import type { CalculatorFormik, CalculatorValues } from '@components/calculator/hooks/useCalculatorFormik';
import type { AnnouncementsRequest, TariffsInfoRequest, TariffsRequest } from '@components/calculator/hooks/useCalculatorRequests';

export interface ChangeReceivingCurrencyCalculatorValues extends CalculatorValues {
  sendingCountry: Country;
  receivingCountry: Country;
  sendingCurrency: Currency;
  receivingCurrency: Currency;
}
export type ChangeReceivingCurrencyCalculatorFormik = CalculatorFormik<ChangeReceivingCurrencyCalculatorValues>;

interface ChangeReceivingCurrencyParams {
  formik: ChangeReceivingCurrencyCalculatorFormik;
  tariffsInfo: TariffsInfoRequest;
  tariffs: TariffsRequest;
  announcements: AnnouncementsRequest;
  setCurrentInfoItem: (infoItem: TransferTariffInfo) => void;
}

export const changeReceivingCurrency = async (
  newReceivingCurrency: Currency,
  {
    formik,
    tariffsInfo,
    tariffs,
    announcements,
    setCurrentInfoItem
  }: ChangeReceivingCurrencyParams
) => {
  const oldSendingCurrency = formik.values.sendingCurrency;
  const oldReceivingCurrency = formik.values.receivingCurrency;
  if (newReceivingCurrency.id === oldReceivingCurrency.id) return;

  await formik.setErrors({});
  await formik.setTouched({});

  const { newInfoItem } = resolveNewInfoItem(
    tariffsInfo.data,
    oldSendingCurrency.id,
    newReceivingCurrency.id
  );
  await formik.setFieldValue('sendingCurrency', newInfoItem.sendingCurrency);
  await formik.setFieldValue('receivingCurrency', newInfoItem.receivingCurrency);
  await formik.setFieldTouched('sendingCurrency', true);
  await formik.setFieldTouched('receivingCurrency', true);

  await announcements.mutate({
    sendingCountryId: formik.values.sendingCountry.id,
    receivingCountryId: formik.values.receivingCountry.id,
    receivingCurrencyId: newInfoItem.receivingCurrency.id,
    process: 'sendingTransfer',
    event: 'directionSelected'
  });

  const isReceivingAmountValid = !!formik.values.receivingAmount && validateAmountLimit({
    amount: multiplyByHundred(+formik.values.receivingAmount),
    amountKey: 'receivingAmount',
    currentInfoItem: newInfoItem
  });
  if (!isReceivingAmountValid) {
    setCurrentInfoItem(newInfoItem);
    if (formik.values.receivingAmount) await formik.validateForm();
    return;
  }

  const tariffsResponse = await tariffs.mutate({
    sendingCountryId: formik.values.sendingCountry.id,
    receivingCountryId: formik.values.receivingCountry.id,
    sendingCurrencyId: newInfoItem.sendingCurrency.id,
    receivingCurrencyId: newInfoItem.receivingCurrency.id,
    receivingAmount: multiplyByHundred(+formik.values.receivingAmount),
    paymentMethod: 'debitCard',
    receivingMethod: 'cash',
    paidNotificationEnabled: formik.values.paidNotification
  });
  if (!tariffsResponse.success) {
    await formik.setFieldValue('sendingCurrency', oldSendingCurrency);
    await formik.setFieldValue('receivingCurrency', oldReceivingCurrency);
    return;
  }
  setCurrentInfoItem(newInfoItem);
  // ✅ important:
  // validate form for delete previous errors
  const [usedTariff] = tariffsResponse.data;
  await formik.setFieldValue('sendingAmount', convertToMoney(usedTariff.sendingAmount).toString());
  await formik.setFieldValue('receivingAmount', convertToMoney(usedTariff.receivingAmount).toString());
  await formik.setFieldTouched('sendingAmount', true);
  await formik.setFieldTouched('receivingAmount', true);
  await formik.validateForm();
};
