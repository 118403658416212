import type { ChangeEvent, KeyboardEvent } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useClickOutside } from '@utils/hooks';

interface useSelectParams {
  options: Option[];
  filterOption: FilterOptionFunc;
  optionValue: Option;
  onChangeSelect: (option: Option) => void;
}

export const useSelect = ({
  options,
  optionValue,
  onChangeSelect,
  filterOption
}: useSelectParams) => {
  const [inputValue, setInputValue] = useState('');
  const selectRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);

  const [showOptions, setShowOptions] = useState(false);

  const [searchSelectedOption, setSearchSelectedOption] = useState({
    index: options?.findIndex((option) => option.id === optionValue?.id),
    id: optionValue?.id
  });

  const filteredOptions = useMemo(
    () => options?.filter((option) => filterOption(option, inputValue)),
    [options, inputValue]
  );

  // ✅ important:
  // When filtering, changing the index of the selected option
  useEffect(() => {
    if (!filteredOptions.length) return;
    const searchOption = filteredOptions.find((option) => searchSelectedOption.id === option.id);
    if (!searchOption) return setSearchSelectedOption({ index: 0, id: filteredOptions[0].id });

    setSearchSelectedOption({
      id: searchOption.id,
      index: filteredOptions.findIndex((option) => option.id === searchOption.id)
    });
  }, [filteredOptions]);

  // ✅ important:
  // Click outside select
  useClickOutside(
    selectRef,
    () => {
      setShowOptions(false);
      const selectedOptionIndex = options.findIndex((el) => el.id === optionValue?.id);
      setSearchSelectedOption({ index: selectedOptionIndex, id: optionValue?.id });
      resetInput();
    },
    [optionValue]
  );

  // ✅ important:
  // Synchronization external and internal selected options
  useEffect(() => {
    if (optionValue?.id !== searchSelectedOption.id) {
      const originalOptionIndex = options.findIndex((el) => el.id === optionValue.id);
      setSearchSelectedOption({ id: optionValue.id, index: originalOptionIndex });
    }
  }, [optionValue]);

  // ✅ important:
  // Scroll to selected option on open and key up/down
  useEffect(() => {
    if (ulRef.current && searchSelectedOption) {
      const optionHeight = ulRef.current.scrollHeight / options.length;
      ulRef.current.scrollTop = optionHeight * searchSelectedOption.index;
    }
  }, [ulRef, searchSelectedOption.id, showOptions]);

  // ✅ important:
  // Reset input statement (focus, value)
  const resetInput = () => {
    if (inputRef.current) inputRef.current.blur();
    setInputValue('');
  };

  const selectOption = (option: Option) => {
    const originalOptionIndex = options.findIndex((el) => el.id === option.id);
    setSearchSelectedOption({ id: option.id, index: originalOptionIndex });
    onChangeSelect(option);
    resetInput();
    setShowOptions(false);
  };

  // ✅ important:
  // Logic for pressing keys up/down/enter for select
  const onSelectKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    const firstOptionIndex = 0;
    const lastOptionIndex = filteredOptions.length - 1;
    if (event.key === 'ArrowUp') {
      event.preventDefault();
      if (searchSelectedOption.index === firstOptionIndex) {
        const lastOption = filteredOptions[lastOptionIndex];
        return setSearchSelectedOption({ id: lastOption.id, index: lastOptionIndex });
      }

      const upperOption = filteredOptions[searchSelectedOption.index - 1];
      return setSearchSelectedOption({
        id: upperOption.id,
        index: searchSelectedOption.index - 1
      });
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      if (searchSelectedOption.index === lastOptionIndex) {
        const firstOption = filteredOptions[firstOptionIndex];
        return setSearchSelectedOption({ id: firstOption.id, index: firstOptionIndex });
      }

      const lowerOption = filteredOptions[searchSelectedOption.index + 1];
      return setSearchSelectedOption({
        id: lowerOption.id,
        index: searchSelectedOption.index + 1
      });
    }
    if (event.key === 'Enter') {
      const option = filteredOptions[searchSelectedOption.index];
      selectOption(option);
    }
  };

  const onItemClick = (option: Option) => selectOption(option);

  const onSelectClick = () => {
    if (inputRef.current && !showOptions) {
      setShowOptions(true);
      inputRef.current.focus();
      return;
    }

    resetInput();
    setShowOptions(false);
  };

  const searchInputHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setInputValue(event.target.value);

  return {
    refs: { selectRef, ulRef, inputRef },
    functions: { onSelectClick, onItemClick, onSelectKeyDown, searchInputHandler },
    state: {
      filteredOptions,
      showOptions,
      searchSelectedOption,
      optionValue,
      inputValue
    }
  };
};
