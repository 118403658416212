import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { CommissionLabel, ExchangeLabel, MoneyLabel } from '@common/labels';

import skeletonStyles from '@common/skeleton/Skeleton.module.scss';
import styles from './TariffsData.module.scss';

interface TariffsDataProps {
  usedTariff: TransferTariff;
  loading: boolean;
}

export const TariffsData: FC<TariffsDataProps> = ({ usedTariff, loading }) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <>
        <div
          className={classnames(styles.commission_load, {
            [skeletonStyles.skeleton]: loading
          })}
        />
        <div
          className={classnames(styles.amount_load, {
            [skeletonStyles.skeleton]: loading
          })}
        />
      </>
    );
  }
  return (
    <>
      <div className={styles.commission}>
        <CommissionLabel
          commission={usedTariff.sendingTransferCommission}
          additionalCommission={usedTariff.paidNotificationCommission}
          currency={usedTariff.sendingCurrency.code}
        />
        {!!usedTariff.exchangeRate && !!usedTariff.exchangeRateType && usedTariff.sendingCurrency.code !== usedTariff.receivingCurrency.code && (
          <span className={styles.exchange}>
            {t('calculator_exchange')}
            :
            {' '}
            <ExchangeLabel
              rate={usedTariff.exchangeRate}
              direction={usedTariff.exchangeRateType}
              currencyTo={usedTariff.receivingCurrency.code}
              currencyFrom={usedTariff.sendingCurrency.code}
            />
          </span>
        )}
      </div>

      <span className={styles.amount}>
        {t('calculator_amount')}
        :
        {' '}
        <MoneyLabel
          amount={usedTariff.sendingAmount}
          currency={usedTariff.sendingCurrency.code}
        />
      </span>
    </>
  );
};
