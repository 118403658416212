import React, {useState} from 'react'
import styles from './category.module.scss'
import {Link} from "gatsby";
import FeedbackForm from "../../../modules/Home/feedbackForm";
import cn from 'classnames'

const Category = ({title, subTitle, groupedArticles, articles = [], inner = false, home = false, activeArticleSlug = null}) => {
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const ItemArticle = ({to, slug, title, activeArticleSlug, inner = false}) => {
        const isActive = slug === activeArticleSlug;
        return (
            <>
                <Link key={slug}
                      className={cn(styles['link'], {[styles['inner']]: inner}, {[styles['open']]: isActive})}
                      to={to}>{title}</Link>
            </>
        );
    };

    const toggleSelectGroup = (groupId) => {
        if (selectedGroupIds.includes(groupId)) {
            setSelectedGroupIds(selectedGroupIds.filter(id => (id !== groupId)));
        } else {
            setSelectedGroupIds([...selectedGroupIds, groupId]);
        }
    };

    return (
        <div className={cn({[styles['sidebar']]: inner && !home  })}>
            {title && !inner && <h1 className={cn({'like_h3': inner}, {[styles['sidebar_title']]: inner})}>{title}</h1>}
            {inner ? <h3 className={cn({'like_h3_normal': inner}, {[styles['sidebar_title']]: inner})}>{title}</h3> :
                <p className={cn(styles['subtitle'], 'text_medium_300')}>{subTitle}</p>}
            <div className={styles['list']}>
                {Object.keys(groupedArticles).length !== 0 &&
                Object.keys(groupedArticles).map(key => {
                        const isClose = !selectedGroupIds.includes(key);
                        return (
                            <div
                                className={inner ? cn(styles['item'], 'text_small_400') : cn(styles['item'], 'text_medium_400')}
                                key={key}>
                                <div className={cn(styles['item_title'], {[styles['open']]: isClose})}
                                     onClick={() => toggleSelectGroup(key)}>
                                    {key}
                                </div>
                                {isClose && groupedArticles[key].map(props => (
                                    <ItemArticle {...props} inner activeArticleSlug={activeArticleSlug}/>
                                ))}
                                <br/>
                            </div>
                        )
                    }
                )
                }
                {articles
                    .sort((a, b) => a.position - b.position)
                    .map((props) => <div
                    className={inner ? cn(styles['item'], 'text_small_400') : cn(styles['item'], 'text_medium_400')}>
                    <ItemArticle {...props} activeArticleSlug={activeArticleSlug}/></div>)}
            </div>
        </div>
    )
};

export default Category
