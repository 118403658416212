import type { RefObject, DependencyList } from 'react';
import { useEffect } from 'react';

export const useClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: any,
  deps: DependencyList = []
): void => {
  const handler = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node) && typeof callback === 'function') {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handler, { capture: true });

    return () => {
      document.removeEventListener('click', handler, { capture: true });
    };
  }, deps);
};
