import merge from 'lodash/merge';

import { def } from './default';
import { dev } from './dev';
import { autotest } from './autotest';

const resolveConfiguration = () => {
  const config = { ...def };
  switch (process.env.NODE_ENV) {
    case 'development':
      merge(config, dev);
      break;
    case 'autotest':
      merge(config, autotest);
      break;
    default:
      break;
  }
  return config;
};

export const configuration = resolveConfiguration();
