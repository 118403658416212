import { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import type { CalculatorFormik } from '@components/calculator/hooks/useCalculatorFormik';
import type { TariffsRequest } from '@components/calculator/hooks/useCalculatorRequests';
import { getPopupAnnouncement, getInPlaceAnnouncements } from '@utils/helpers/selectors/announcements/announcements';
import { getWarningsPriority0, getWarningPriority1 } from '@utils/helpers/selectors/announcements/warnings';
import { getCalculatorSchema } from '@utils/helpers/schemas';
import { convertToMoney } from '@utils/helpers/money/money';
import { useNonInitialEffect } from '@utils/hooks';
import { useCalculatorFormik } from '@components/calculator/hooks/useCalculatorFormik';
import { useCalculatorRequests } from '@components/calculator/hooks/useCalculatorRequests';
import { useCalculatorLoading } from '@components/calculator/hooks/useCalculatorLoading';
import { useCalculatorInitial } from '@components/calculator/hooks/useCalculatorInitial';
import { MS_COEFFICIENT } from '@utils/constants';

import type {
  ChangeSendingCountryCalculatorFormik,
  ChangeReceivingCountryCalculatorFormik,
  ChangeSendingCurrencyCalculatorFormik,
  ChangeReceivingCurrencyCalculatorFormik,
  ChangeAmountCalculatorFormik
} from '../handlers';
import {
  changeSendingCountry as handleChangeSendingCountry,
  changeReceivingCountry as handleChangeReceivingCountry,
  changeSendingCurrency as handleChangeSendingCurrency,
  changeReceivingCurrency as handleChangeReceivingCurrency,
  changeAmount as handleChangeAmount
} from '../handlers';

export const useCalculator = () => {
  const [currentInfoItem, setCurrentInfoItem] = useState<TransferTariffInfo | undefined>(undefined);
  const [showNotificationSendingAmount, setShowNotificationSendingAmount] = useState(false);

  const [showTransferUnavailablePopup, setShowTransferUnavailablePopup] = useState(false);
  const [showWarningPopup, setShowWarningPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorPopupContent, setErrorPopupContent] = useState<string | undefined>(undefined);

  const formik = useCalculatorFormik({
    initialValues: {
      sendingCountry: null,
      receivingCountry: null,
      sendingCurrency: null,
      receivingCurrency: null,
      sendingAmount: '',
      receivingAmount: '',
      paidNotification: false
    },
    validationSchema: useMemo(() => getCalculatorSchema(
      convertToMoney(Number(currentInfoItem?.minReceivingAmount)),
      convertToMoney(Number(currentInfoItem?.maxReceivingAmount)),
      convertToMoney(Number(currentInfoItem?.minSendingAmount)),
      convertToMoney(Number(currentInfoItem?.maxSendingAmount)),
      currentInfoItem?.fractionalReceivingAmountUsage
    ), [currentInfoItem]),
    onSubmit: () => { setShowTransferUnavailablePopup(true); }
  });

  const requests = useCalculatorRequests({ setShowErrorPopup, setErrorPopupContent });
  const calculatorLoading = useCalculatorLoading();
  const calculatorInitial = useCalculatorInitial({ formik, requests, setCurrentInfoItem });

  const { popupAnnouncement } = getPopupAnnouncement(requests.announcements.data);
  const { inPlaceAnnouncements } = getInPlaceAnnouncements(requests.announcements.data);

  const { warningsPriority0 } = getWarningsPriority0(currentInfoItem, formik.values.receivingCurrency?.id);
  const { warningPriority1 } = getWarningPriority1(currentInfoItem, formik.values.receivingCurrency?.id);

  const changeSendingCountry = calculatorLoading.createLoadingWrappedFn(async (country: Country) => {
    await handleChangeSendingCountry(country, {
      formik: formik as ChangeSendingCountryCalculatorFormik,
      receivingCountries: requests.receivingCountries,
      tariffsInfo: requests.tariffsInfo,
      tariffs: requests.tariffs,
      announcements: requests.announcements,
      setCurrentInfoItem
    });
  }, 'sendingCountry');
  const changeReceivingCountry = calculatorLoading.createLoadingWrappedFn(async (country: Country) => {
    await handleChangeReceivingCountry(country, {
      formik: formik as ChangeReceivingCountryCalculatorFormik,
      tariffsInfo: requests.tariffsInfo,
      tariffs: requests.tariffs,
      announcements: requests.announcements,
      setCurrentInfoItem
    });
  }, 'receivingCountry');

  const changeSendingCurrency = calculatorLoading.createLoadingWrappedFn(async (currency: Currency) => {
    await handleChangeSendingCurrency(currency, {
      formik: formik as ChangeSendingCurrencyCalculatorFormik,
      tariffsInfo: requests.tariffsInfo,
      tariffs: requests.tariffs,
      announcements: requests.announcements,
      setCurrentInfoItem
    });
  }, 'sendingCurrency');
  const changeReceivingCurrency = calculatorLoading.createLoadingWrappedFn(async (currency: Currency) => {
    await handleChangeReceivingCurrency(currency, {
      formik: formik as ChangeReceivingCurrencyCalculatorFormik,
      tariffsInfo: requests.tariffsInfo,
      tariffs: requests.tariffs,
      announcements: requests.announcements,
      setCurrentInfoItem
    });
  }, 'receivingCurrency');

  const debouncedChangeAmount = useCallback(
    debounce((formik: CalculatorFormik, tariffs: TariffsRequest, amount: string, key: 'sendingAmount' | 'receivingAmount', isAmountValid: boolean) => {
      if (!isAmountValid) return;
      if (key === 'sendingAmount') handleChangeSendingAmount(amount, key, { formik: formik as ChangeAmountCalculatorFormik, tariffs });
      if (key === 'receivingAmount') handleChangeReceivingAmount(amount, key, { formik: formik as ChangeAmountCalculatorFormik, tariffs });
    }, MS_COEFFICIENT * 0.5),
    []
  );
  const changeAmount = async (amount: string, key: 'sendingAmount' | 'receivingAmount') => {
    await formik.setFieldValue(key, +amount ? amount : '', false);
    await formik.setFieldTouched(key, true, false);
    const errors = await formik.validateForm();
    const isAmountValid = !errors || !errors[key];
    debouncedChangeAmount(formik, requests.tariffs, amount, key, isAmountValid);
  };
  const handleChangeSendingAmount = calculatorLoading.createLoadingWrappedFn(handleChangeAmount, 'sendingAmount');
  const handleChangeReceivingAmount = calculatorLoading.createLoadingWrappedFn(handleChangeAmount, 'receivingAmount');

  useNonInitialEffect(() => {
    if (warningPriority1?.body || popupAnnouncement) setShowWarningPopup(true);
  }, [warningPriority1?.body, popupAnnouncement]);

  const formLoading = calculatorLoading.loading;
  const formDisabled = calculatorInitial.loading || (!requests.sendingCountries.data.length || !requests.receivingCountries.data.length || !requests.tariffsInfo.data.length);

  return {
    fields: {
      sendingCountry: {
        value: formik.values.sendingCountry,
        error: formik.errors.sendingCountry,
        isError: !!formik.errors.sendingCountry && !!formik.touched.sendingCountry,
        data: requests.sendingCountries.data,
        loading: calculatorLoading.checkFieldIsLoading('sendingCountry') || calculatorInitial.loading,
        disabled: calculatorLoading.checkFieldIsDisabled('sendingCountry') || !formik.values.sendingCountry,
        changeCountry: changeSendingCountry
      },
      receivingCountry: {
        value: formik.values.receivingCountry,
        error: formik.errors.receivingCountry,
        isError: !!formik.errors.receivingCountry && !!formik.touched.receivingCountry,
        data: requests.receivingCountries.data,
        loading: calculatorLoading.checkFieldIsLoading('receivingCountry') || calculatorInitial.loading,
        disabled: calculatorLoading.checkFieldIsDisabled('receivingCountry') || !formik.values.receivingCountry,
        changeCountry: changeReceivingCountry
      },
      sendingCurrencyWithAmount: {
        currencyValue: formik.values.sendingCurrency,
        amountValue: formik.values.sendingAmount,
        error: formik.errors.sendingCurrency || formik.errors.sendingAmount,
        isError: (!!formik.errors.sendingCurrency && !!formik.touched.sendingCurrency) || (!!formik.errors.sendingAmount && !!formik.touched.sendingAmount),
        data: requests.tariffsInfo.data,
        loading: calculatorLoading.checkFieldIsLoading('sendingCurrency') || calculatorInitial.loading,
        disabled: calculatorLoading.checkFieldIsDisabled('sendingCurrency') || !formik.values.sendingCurrency || currentInfoItem?.fractionalReceivingAmountUsage !== 'enabled',
        changeCurrency: changeSendingCurrency,
        changeAmount
      },
      receivingCurrencyWithAmount: {
        currencyValue: formik.values.receivingCurrency,
        amountValue: formik.values.receivingAmount,
        error: formik.errors.receivingCurrency || formik.errors.receivingAmount,
        isError: (!!formik.errors.receivingCurrency && !!formik.touched.receivingCurrency) || (!!formik.errors.receivingAmount && !!formik.touched.receivingAmount),
        data: requests.tariffsInfo.data,
        loading: calculatorLoading.checkFieldIsLoading('receivingCurrency') || calculatorInitial.loading,
        disabled: calculatorLoading.checkFieldIsDisabled('receivingCurrency') || !formik.values.receivingCurrency,
        changeCurrency: changeReceivingCurrency,
        changeAmount
      },
      tariffs: {
        data: requests.tariffs.data,
        loading: formLoading
      }
    },
    form: {
      loading: formLoading,
      disabled: formDisabled,
      handleSubmit: formik.handleSubmit
    },
    popupTransferUnavailable: {
      showPopup: showTransferUnavailablePopup,
      setShowPopup: setShowTransferUnavailablePopup
    },
    popupWarning: {
      showPopup: showWarningPopup,
      setShowPopup: setShowWarningPopup,
      content: warningPriority1 ?? popupAnnouncement
    },
    popupError: {
      showPopup: showErrorPopup,
      setShowPopup: setShowErrorPopup,
      content: errorPopupContent,
      setContent: setErrorPopupContent
    },
    inPlaceWarnings: warningsPriority0?.length ? warningsPriority0 : inPlaceAnnouncements,
    currentInfoItem,
    notifications: {
      sendingAmount: {
        showNotification: showNotificationSendingAmount,
        setShowNotification: setShowNotificationSendingAmount
      }
    }
  };
};
