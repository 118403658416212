import React from 'react';
import styles from './home.module.scss';
// import Title from './title';
import { Banner } from '../../../srcv2/modules/Home/Banner/Banner';
import { BannerOld } from '../../../srcv2/modules/Home/BannerOld/BannerOld';
// import DownloadAppWhite from "./downloadAppWhite"
import DownloadAppRed from './downloadAppRed';
import DownloadAppGrey from './downloadAppGrey';
import HowToSteps from './howToSteps';
import Button from 'components/common/Button';
import FeedbackForm from './feedbackForm';
import Geography from './geography';
import { useTranslation } from 'react-i18next';
import Category from 'components/common/category';
import TrustBox from './TrustBox';
import Calculator from '../../components/common/calculatorFrame';

const Home = ({ categoryData, location, showCalculator = false }) => {
    const {t} = useTranslation();

    return (
        <>
            {/*<Title/>*/}
            {showCalculator ? <Banner /> : <BannerOld />}
            <div className={styles['wrapper']}>
                <div className={'container'}>
                    {/*<DownloadAppWhite/>*/}
                    <TrustBox />
                    {/*<Calculator location={location} />*/}
                    <HowToSteps/>
                    <Geography location={location}/>
                    <DownloadAppRed/>
                    <article className={'group_margin'}>
                        <h2 className={styles['title']}>{t('FAQ_title')}</h2>
                        <div className={'group_margin'}>
                            <div className={styles['faq_container']}>
                                <Category
                                    articles={categoryData.articles}
                                    groupedArticles={categoryData.groupedArticles}
                                    inner
                                    home
                                />
                            </div>
                            <Button color={'blue_border'}
                                    size={'medium'}
                                    href to={'/help/'}
                                    name={t('FAQ_buttonName')} />
                        </div>
                        <FeedbackForm/>
                    </article>
                    <DownloadAppGrey/>
                    <div>&nbsp; </div>
                </div>
            </div>
        </>
    );
};

export default Home;
