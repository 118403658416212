import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { OPERATING_SYSTEM, MARKET_LINKS } from '@utils/constants';

import styles from './MarketLinks.module.scss';

interface MarketLinksProps {
  operatingSystem: 'android' | 'ios';
}

export const MarketLinks: FC<MarketLinksProps> = ({ operatingSystem }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language as 'en' | 'ru';

  return (
    <>
      {operatingSystem === OPERATING_SYSTEM.IOS && (
        <a
          className={classnames(styles.link, `appstore_${language}`)}
          href={MARKET_LINKS.APP_STORE[language]}
        >
          {t('header_downloadApp_appStoreHrefTitle')}
        </a>
      )}
      {operatingSystem === OPERATING_SYSTEM.ANDROID && (
        <a
          className={classnames(styles.link, `gplay_${language}`)}
          href={MARKET_LINKS.GOOGLE_PLAY[language]}
        >
          {t('header_downloadApp_googleHrefTitle')}
        </a>
      )}
    </>
  );
};
