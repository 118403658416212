import type { FC } from 'react';
import React from 'react';

import { Popup } from '@common/popups';

import styles from '../WarningPopup/WarningPopup.module.scss';

export const ErrorPopup: FC<ErrorPopupProps> = ({ error, onClose }) => (
  <Popup onClose={onClose}>
    <p className={styles.desc}>{error}</p>
  </Popup>
);
