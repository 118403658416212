import type { FC } from 'react';
import React, { useRef } from 'react';
import classnames from 'classnames';

import styles from '../Button.module.scss';

export const Button: FC<ButtonProps> = ({
  id,
  variant,
  buttonSize = 'medium',
  loading,
  disabled,
  onClick,
  children,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const className = classnames(styles[variant], {
    [styles.loading]: loading,
    [styles.disabled]: disabled,
    [buttonSize && styles[buttonSize]]: buttonSize
  });

  return (
    <button
      id={id}
      ref={buttonRef}
      type="button"
      disabled={loading || disabled}
      className={className}
      onClick={(event) => {
        if (onClick) onClick(event);
        if (buttonRef.current) buttonRef.current.blur();
      }}
      {...props}
    >
      <span>{children}</span>
    </button>
  );
};
