import {useTranslation} from "react-i18next";
import Select from "react-select";
import React from "react";

const ReactSelectAdapter = ({ placeholder, input, meta, ...rest }) => {
    const {t} = useTranslation();
    const customStyles = {
        container: (base, state) => ({
            ...base,
            "&:hover": {
                boxShadow: "0 0.125rem 0.25rem 0 rgba(41, 56, 78, 0.1)",
            }
        }),
        control: (base, state) => ({
            ...base,
            border: meta.error && meta.touched ? '1px solid #ff5252' :  '1px solid #e0e0e0',
            borderRadius: '.125rem',
            height: '3rem',
            color: '#424242',
            width: '100%',
            paddingLeft: '1rem',
            marginBottom: '2rem',
        }),
        valueContainer: (base, state) => ({
            ...base,
            fontWeight: 300,
            color: '#424242',
            padding: 0
        }),
        placeholder: (base, state) => ({
            ...base,
            color: '#969595'
        })
    };

    return <>
        <Select styles={customStyles} {...input} {...rest} placeholder={placeholder} />
        {meta.error && meta.touched && <p className={'help_error'}>{t(meta.error)}</p>}
    </>
};

export default ReactSelectAdapter;
