import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@common/buttons';
import { CountriesSelect, InputWithSelect, NumberInput } from '@common/fields';
import { WarningBlock } from '@common/warnings';
import { TransferUnavailablePopup, ErrorPopup, WarningPopup } from '@common/popups';
import { TariffsData } from '@components/calculator/TariffsData/TariffsData';
import { useCalculator } from '@components/calculator/hooks/useCalculator';
import { getEuropeanSendingCountries } from '@utils/helpers/selectors/countries/countries';
import { getSendingCurrencies, getReceivingCurrencies } from '@utils/helpers/selectors/currencies/currencies';
import { convertToMoney } from '@utils/helpers/money/money';

import styles from './Calculator.module.scss';

export const Calculator: FC = () => {
  const {
    fields,
    form,
    popupTransferUnavailable,
    popupWarning,
    popupError,
    inPlaceWarnings,
    currentInfoItem,
    notifications
  } = useCalculator();
  const { t } = useTranslation();

  const { sendingCountries } = getEuropeanSendingCountries(fields.sendingCountry.data);
  const receivingCountries = fields.receivingCountry.data;

  const sendingCurrencyOptions = getSendingCurrencies(fields.sendingCurrencyWithAmount.data, 'cash')
    .sendingCurrencies.map((currency: Currency) => ({
      label: currency.code,
      id: currency.id,
      value: currency
    }));
  const receivingCurrencyOptions = getReceivingCurrencies(fields.receivingCurrencyWithAmount.data, fields.sendingCurrencyWithAmount.currencyValue, 'cash')
    .receivingCurrencies.map((currency: Currency) => ({
      label: currency.code,
      id: currency.id,
      value: currency
    }));

  const isShowWarning = !!fields.receivingCountry.value && !!inPlaceWarnings?.length && !form.loading;
  const isShowTariffsData = !!fields.receivingCurrencyWithAmount.amountValue && !fields.receivingCurrencyWithAmount.error && (!!fields.tariffs.data.length || fields.tariffs.loading);

  return (
    <form className={styles.container} onSubmit={form.handleSubmit}>
      <div className={styles.row}>
        <CountriesSelect
          label={t('input.sendingCountry')}
          isError={fields.sendingCountry.isError && !fields.sendingCountry.loading}
          helperText={fields.sendingCountry.error && t(fields.sendingCountry.error)}
          selectedCountry={fields.sendingCountry.value}
          countries={sendingCountries}
          loading={fields.sendingCountry.loading}
          disabled={fields.sendingCountry.disabled}
          changeCountry={(country: Country) => fields.sendingCountry.changeCountry(country)}
          noOptionsText={t('input.noOptions')}
        />
        <CountriesSelect
          label={t('input.receivingCountry')}
          isError={fields.receivingCountry.isError && !fields.receivingCountry.loading}
          helperText={fields.receivingCountry.error && t(fields.receivingCountry.error)}
          selectedCountry={fields.receivingCountry.value}
          countries={receivingCountries}
          loading={fields.receivingCountry.loading}
          disabled={fields.receivingCountry.disabled}
          changeCountry={(country: Country) => fields.receivingCountry.changeCountry(country)}
          noOptionsText={t('input.noOptions')}
        />
      </div>
      <div className={styles.row}>
        <InputWithSelect
          {...(currentInfoItem && currentInfoItem.fractionalReceivingAmountUsage !== 'enabled' && {
            onClick: () => notifications.sendingAmount.setShowNotification(true),
            onClickOutside: () => notifications.sendingAmount.setShowNotification(false)
          })}
          label={t('input.sendingAmount')}
          isError={fields.sendingCurrencyWithAmount.isError && !fields.sendingCurrencyWithAmount.loading}
          helperText={fields.sendingCurrencyWithAmount.error && t(fields.sendingCurrencyWithAmount.error, {
            ...(currentInfoItem?.sendingCurrency && { currency: currentInfoItem.sendingCurrency.code }),
            ...(currentInfoItem?.minSendingAmount && { minAmount: convertToMoney(currentInfoItem.minSendingAmount) }),
            ...(currentInfoItem?.maxSendingAmount && { maxAmount: convertToMoney(currentInfoItem.maxSendingAmount) })
          })}
          loading={fields.sendingCurrencyWithAmount.loading}
          disabled={fields.sendingCurrencyWithAmount.disabled}
          inputValue={fields.sendingCurrencyWithAmount.amountValue}
          allowedPennies
          onChangeInput={(value) => fields.sendingCurrencyWithAmount.changeAmount(value, 'sendingAmount')}
          options={sendingCurrencyOptions}
          optionValue={sendingCurrencyOptions.find((el) => el.id === fields.sendingCurrencyWithAmount.currencyValue?.id) ?? null}
          onChangeSelect={(option) => fields.sendingCurrencyWithAmount.changeCurrency(option.value)}
          components={{ Input: NumberInput }}
        />
        <InputWithSelect
          label={t('input.receivingAmount')}
          isError={fields.receivingCurrencyWithAmount.isError && !fields.receivingCurrencyWithAmount.loading}
          helperText={fields.receivingCurrencyWithAmount.error && t(fields.receivingCurrencyWithAmount.error, {
            ...(currentInfoItem?.receivingCurrency && { currency: currentInfoItem.receivingCurrency.code }),
            ...(currentInfoItem?.minReceivingAmount && { minAmount: convertToMoney(currentInfoItem.minReceivingAmount) }),
            ...(currentInfoItem?.maxReceivingAmount && { maxAmount: convertToMoney(currentInfoItem.maxReceivingAmount) })
          })}
          loading={fields.receivingCurrencyWithAmount.loading}
          disabled={fields.receivingCurrencyWithAmount.disabled}
          inputValue={fields.receivingCurrencyWithAmount.amountValue}
          allowedPennies={currentInfoItem?.fractionalReceivingAmountUsage === 'enabled'}
          onChangeInput={(value) => fields.receivingCurrencyWithAmount.changeAmount(value, 'receivingAmount')}
          options={receivingCurrencyOptions}
          optionValue={receivingCurrencyOptions.find((el) => el.id === fields.receivingCurrencyWithAmount.currencyValue?.id) ?? null}
          onChangeSelect={(option) => fields.receivingCurrencyWithAmount.changeCurrency(option.value)}
          components={{ Input: NumberInput }}
        />
      </div>

      {popupTransferUnavailable.showPopup && fields.receivingCountry.value && (
        <TransferUnavailablePopup
          countryIdForAppLink={fields.receivingCountry.value.id}
          onClose={() => popupTransferUnavailable.setShowPopup(false)}
        />
      )}
      {popupWarning.showPopup && popupWarning.content && (
        <WarningPopup
          warning={popupWarning.content}
          onClose={() => popupWarning.setShowPopup(false)}
        />
      )}
      {popupError.showPopup && popupError.content && (
        <ErrorPopup
          error={popupError.content}
          onClose={() => {
            popupError.setShowPopup(false);
            popupError.setContent(undefined);
          }}
        />
      )}

      {notifications.sendingAmount.showNotification && <WarningBlock warnings={[{ body: t('calculator.warnings.impossibleChangeSendingAmount') }]} type="attention" />}
      {isShowWarning && <WarningBlock warnings={inPlaceWarnings} />}
      {isShowTariffsData && <TariffsData usedTariff={fields.tariffs.data[0]} loading={fields.tariffs.loading} />}

      <div className={styles.row_button}>
        <Button
          type="submit"
          variant="contained"
          loading={form.loading && !form.disabled}
          disabled={form.disabled}
        >
          {t('calculator_button')}
        </Button>
      </div>
      <div className={styles.legal}>
        {t('calculator_legal')}
      </div>
    </form>
  );
};
