import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Linear } from '@common/linear/Linear';
import { GlobeIcon } from '@common/svg/icons/GlobeIcon';
import { PhoneIcon } from '@common/svg/icons/PhoneIcon';

import styles from './SelectInformation.module.scss';

interface SelectInformationProps {
  globeOnClick: () => void;
  phoneOnClick: () => void;
}

export const SelectInformation: FC<SelectInformationProps> = ({ globeOnClick, phoneOnClick }) => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className={styles.title}>
        {t('calculator.popup.sendMoney')}
      </h3>
      <div className={styles.actions}>
        <Linear
          variant="outlined"
          startIcon={<GlobeIcon />}
          onClick={globeOnClick}
          className={styles.actions_item}
        >
          {t('calculator.popup.onTheWebsite')}
        </Linear>
        <Linear
          variant="outlined"
          startIcon={<PhoneIcon />}
          onClick={phoneOnClick}
          className={styles.actions_item}
        >
          {t('calculator.popup.inTheMobileApp')}
        </Linear>
      </div>
    </>
  );
};
