import type { UseMutateAsyncFunction } from 'react-query';
import { useRef } from 'react';
import { useMutation } from 'react-query';
import { requestSendingCountries } from '@utils/api/transfers/directions/points/receiving';
import { requestReceivingCountries } from '@utils/api/transfers/directions/points/sending';
import { requestTariffsInfo } from '@utils/api/transfers/tariffs/info';
import { requestAnnouncements } from '@utils/api/transfers/announcements';
import { requestTariffs } from '@utils/api/transfers/tariffs';

interface Request<Data, Params> {
  data: Data;
  error: ApiError | null;
  loading: boolean;
  mutate: UseMutateAsyncFunction<ApiResponse<Data>, unknown, Params, unknown>;
}
export type SendingCountriesRequest = Request<TransferDirectionPoint[], void>;
export type ReceivingCountriesRequest = Request<TransferDirectionPoint[], ReceivingCountriesReqGetParams>;
export type TariffsInfoRequest = Request<TransferTariffInfo[], TransfersTariffsInfoReqGetParams>;
export type TariffsRequest = Request<TransferTariff[], TransfersTariffsReqGetParams>;
export type AnnouncementsRequest = Request<TransferAnnouncement[], TransfersAnnouncementsReqGetParams>;

export interface CalculatorRequests {
  sendingCountries: SendingCountriesRequest;
  receivingCountries: ReceivingCountriesRequest;
  tariffsInfo: TariffsInfoRequest;
  tariffs: TariffsRequest;
  announcements: AnnouncementsRequest;
}

export interface CalculatorRequestsMutationMemo {
  receivingCountries: TransferDirectionPoint[];
  tariffsInfo: TransferTariffInfo[];
}

interface UseCalculatorRequestsParams {
  setShowErrorPopup: FunctionType;
  setErrorPopupContent: FunctionType;
}

export const useCalculatorRequests = ({ setShowErrorPopup, setErrorPopupContent }: UseCalculatorRequestsParams): CalculatorRequests => {
  // ✅ important:
  // 'mutationMemoRef' used for contain old values while mutation fetching
  const mutationMemoRef = useRef<CalculatorRequestsMutationMemo>({
    receivingCountries: [],
    tariffsInfo: []
  });

  const {
    data: sendingCountriesResponse,
    isLoading: sendingCountriesLoading,
    mutateAsync: sendingCountriesMutation
  } = useMutation(() => requestSendingCountries({ receivingMethod: 'cash' }));
  const sendingCountriesData = sendingCountriesResponse?.success ? sendingCountriesResponse.data : [];
  const sendingCountriesError = sendingCountriesResponse && !sendingCountriesResponse.success ? sendingCountriesResponse.data : null;

  const {
    data: receivingCountriesResponse,
    isLoading: receivingCountriesLoading,
    mutateAsync: receivingCountriesMutation
  } = useMutation((variables: ReceivingCountriesReqGetParams) => requestReceivingCountries(variables), {
    onSuccess: (data) => {
      if (!data.success) {
        setShowErrorPopup(true);
        setErrorPopupContent(data.data.message);
      }
    }
  });
  const receivingCountriesData = receivingCountriesResponse?.success ? receivingCountriesResponse.data : [];
  const receivingCountriesError = receivingCountriesResponse && !receivingCountriesResponse.success ? receivingCountriesResponse.data : null;
  mutationMemoRef.current.receivingCountries = receivingCountriesData.length ? receivingCountriesData : mutationMemoRef.current.receivingCountries;

  const {
    data: tariffsInfoResponse,
    isLoading: tariffsInfoLoading,
    mutateAsync: tariffsInfoMutation
  } = useMutation((variables: TransfersTariffsInfoReqGetParams) => requestTariffsInfo(variables), {
    onSuccess: (data) => {
      if (!data.success) {
        setShowErrorPopup(true);
        setErrorPopupContent(data.data.message);
      }
    }
  });
  const tariffsInfoData = tariffsInfoResponse?.success ? tariffsInfoResponse.data : [];
  const tariffsInfoError = tariffsInfoResponse && !tariffsInfoResponse.success ? tariffsInfoResponse.data : null;
  mutationMemoRef.current.tariffsInfo = tariffsInfoData.length ? tariffsInfoData : mutationMemoRef.current.tariffsInfo;

  const {
    data: tariffsResponse,
    isLoading: tariffsLoading,
    mutateAsync: tariffsMutation
  } = useMutation((variables: TransfersTariffsReqGetParams) => requestTariffs(variables), {
    onSuccess: (data) => {
      if (!data.success) {
        setShowErrorPopup(true);
        setErrorPopupContent(data.data.message);
      }
    }
  });
  const tariffsData = tariffsResponse?.success ? tariffsResponse.data : [];
  const tariffsError = tariffsResponse && !tariffsResponse.success ? tariffsResponse.data : null;

  const {
    data: announcementsResponse,
    isLoading: announcementsLoading,
    mutateAsync: announcementsMutation
  } = useMutation((variables: TransfersAnnouncementsReqGetParams) => requestAnnouncements(variables));
  const announcementsData = announcementsResponse?.success ? announcementsResponse.data : [];
  const announcementsError = announcementsResponse && !announcementsResponse.success ? announcementsResponse.data : null;

  return {
    sendingCountries: {
      data: sendingCountriesData,
      error: sendingCountriesError,
      loading: sendingCountriesLoading,
      mutate: sendingCountriesMutation
    },
    receivingCountries: {
      data: mutationMemoRef.current.receivingCountries,
      error: receivingCountriesError,
      loading: receivingCountriesLoading,
      mutate: receivingCountriesMutation
    },
    tariffsInfo: {
      data: mutationMemoRef.current.tariffsInfo,
      error: tariffsInfoError,
      loading: tariffsInfoLoading,
      mutate: tariffsInfoMutation
    },
    tariffs: {
      data: tariffsData,
      error: tariffsError,
      loading: tariffsLoading,
      mutate: tariffsMutation
    },
    announcements: {
      data: announcementsData,
      error: announcementsError,
      loading: announcementsLoading,
      mutate: announcementsMutation
    }
  };
};
