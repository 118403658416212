import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MoneyLabel } from '@common/labels';

import styles from './CommissionLabel.module.scss';

export const CommissionLabel: FC<CommissionLabelProps> = ({
  currency,
  commission,
  additionalCommission
}) => {
  const { t } = useTranslation();
  if (!commission && !additionalCommission) {
    return <span className={styles.without_commission}>{t('calculator_no_commission')}</span>;
  }

  return (
    <span className={styles.commission}>
      {t('calculator_commission')}:{' '}
      {!!commission && <MoneyLabel amount={commission} currency={currency} />}
      {!!commission && !!additionalCommission && <span> + </span>}
      {!!additionalCommission && <MoneyLabel amount={additionalCommission} currency={currency} />}
    </span>
  );
};
