import type { FC } from 'react';
import React from 'react';
import classnames from 'classnames';

import styles from './WarningBlock.module.scss';

interface WarningBlockProps {
  warnings: WarningMessage[];
  showCaption?: boolean;
  type?: 'error' | 'attention' | 'info';
}

export const WarningBlock: FC<WarningBlockProps> = ({ warnings, showCaption = false, type = 'error' }) => (
  <>
    {warnings.map((warning, index) => (
      <div
        className={classnames(styles.container, { [styles.attention]: type === 'attention', [styles.info]: type === 'info' })}
        key={`warning_${index}`}
      >
        {!!warning.caption && showCaption && (
          <h3 className={styles.warning_title}>
            {warning.caption}
          </h3>
        )}
        {!!warning.body && (
          <p className={styles.warning_text}>
            {warning.body}
          </p>
        )}
      </div>
    ))}
  </>
);
