import type { FC, RefObject } from 'react';
import React from 'react';
import classnames from 'classnames';

import { Label, ValidationMessage } from '@common/forms';

import styles from '@common/fields/selects/styles/Select.module.scss';
import dropdownStyles from '@common/fields/selects/styles/Dropdown.module.scss';
import optionStyles from '@common/fields/selects/styles/Options.module.scss';
import skeletonStyles from '@common/skeleton/Skeleton.module.scss';

import { useClickOutside } from '@utils/hooks';
import { useInputWithSelect } from './hooks/useInputWithSelect';

interface InputWithSelectProps extends FieldProps {
  onClick?: () => void;
  onClickOutside?: () => void;
  inputValue: string;
  allowedPennies: boolean;
  onChangeInput: (value: string) => void;
  inputRef?: RefObject<HTMLInputElement>;
  options: Option[];
  optionValue: Option | null;
  onChangeSelect: (option: Option) => void;
  selectRef?: RefObject<HTMLInputElement>;
  ulRef?: RefObject<HTMLUListElement>;
  components?: {
    Input?: any;
    Option?: any;
    SelectedValue?: any;
  };
}

export const InputWithSelect: FC<InputWithSelectProps> = ({
  onClick,
  onClickOutside,
  id,
  loading,
  disabled,
  isError,
  label,
  helperText,
  inputValue,
  allowedPennies,
  onChangeInput,
  inputRef,
  options,
  optionValue,
  onChangeSelect,
  selectRef,
  ulRef,
  components
}) => {
  const externalRefs = { inputRef, selectRef, ulRef };
  const { refs, state, functions } = useInputWithSelect({
    options, optionValue, onChangeSelect, externalRefs
  });

  useClickOutside(refs.selectRef, onClickOutside, [typeof onClickOutside]);
  const showValidationMessage = isError && helperText;

  const optionItems = options.map((option) => (
    <li
      key={option.id}
      role="option"
      aria-selected={optionValue?.id === option.id}
      aria-hidden="true"
      className={classnames(optionStyles.dropdown_option, { [optionStyles.selected]: optionValue?.id === option.id || state.searchSelectedOption.id === option.id })}
      onClick={() => functions.onItemClick(option)}
    >
      <div className={optionStyles.option}>
        {components?.Option ? <components.Option option={option} /> : option.label}
      </div>
    </li>
  ));

  return (
    <div
      id={id}
      ref={refs.selectRef}
      className={styles.container}
      {...(onClick && onClickOutside && {
        role: 'button',
        tabIndex: -1,
        onClick,
        onKeyPress: (event) => (event.key === 'Enter') && onClick()
      })}
    >
      <div
        className={classnames(styles.container_select, {
          [styles.error]: isError,
          [skeletonStyles.skeleton]: loading,
          [styles.disabled]: disabled
        })}
      >
        <div
          className={styles.option_container}
          aria-hidden="true"
          onClick={functions.onInputContainerClick}
          role="group"
        >
          {components?.Input ? (
            <components.Input
              type="text"
              id="select"
              autoComplete="off"
              value={inputValue}
              allowedPennies={allowedPennies}
              disabled={disabled}
              ref={refs.inputRef}
              onChange={onChangeInput}
              className={styles.option_input}
            />
          ) : (
            <input
              type="text"
              id="select"
              autoComplete="off"
              value={inputValue}
              disabled={disabled}
              ref={refs.inputRef}
              onChange={(event) => onChangeInput(event.target.value)}
              className={styles.option_input}
            />
          )}
          <Label label={label} />
        </div>

        <div
          aria-hidden="true"
          role="menu"
          className={styles.outline}
          onKeyDown={functions.onSelectKeyDown}
          {...(!disabled && { tabIndex: 0, onClick: functions.onSelectClick })}
        >
          <div className={optionStyles.option_section}>
            {optionValue && (
              <div className={optionStyles.option}>
                {components?.SelectedValue ? (
                  <components.SelectedValue option={optionValue} />
                ) : (
                  optionValue.label
                )}
              </div>
            )}
            <span
              className={classnames(styles.arrow, optionStyles.arrow, {
                [styles.open]: state.showOptions
              })}
            />
          </div>
          {state.showOptions && (
            <div className={dropdownStyles.container_input_options}>
              <ul ref={refs.ulRef} className={dropdownStyles.dropdown_content}>
                {optionItems}
              </ul>
            </div>
          )}
        </div>
      </div>
      {showValidationMessage && <ValidationMessage id="select" message={helperText} />}
    </div>
  );
};
