import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MarketLinks } from '@common/MarketLinks/MarketLinks';

import styles from './MobileAppInformation.module.scss';

export const MobileAppInformation: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className={styles.title}>
        {t('calculator.popup.sendInMobileApp')}
      </h3>

      <div className={styles.redirection}>
        <div className={styles.qr} />
        <p className={styles.text}>
          {t('calculator.popup.downloadMobileApp')}
        </p>
      </div>

      <div className={styles.market_links}>
        <MarketLinks operatingSystem="android" />
        <MarketLinks operatingSystem="ios" />
      </div>
    </>
  );
};
