import { useState, useEffect } from 'react';

import type { CalculatorFormik } from '@components/calculator/hooks/useCalculatorFormik';
import type { CalculatorRequests } from '@components/calculator/hooks/useCalculatorRequests';
import { getDefaultSendingCountry, getDefaultReceivingCountry, getEuropeanSendingCountries } from '@utils/helpers/selectors';

interface UseCalculatorInitialParams {
  formik: CalculatorFormik;
  requests: CalculatorRequests;
  setCurrentInfoItem: (infoItem: TransferTariffInfo) => void;
}

export const useCalculatorInitial = ({ formik, requests, setCurrentInfoItem }: UseCalculatorInitialParams) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const requestInitialData = async () => {
      const sendingCountriesResponse = await requests.sendingCountries.mutate();
      if (!sendingCountriesResponse.success || !sendingCountriesResponse.data.length) {
        setLoading(false);
        return;
      }
      const { sendingCountries } = getEuropeanSendingCountries(sendingCountriesResponse.data);
      const { defaultSendingCountry } = getDefaultSendingCountry(sendingCountries, {});

      const receivingCountriesResponse = await requests.receivingCountries.mutate({ sendingCountryId: defaultSendingCountry.id });
      if (!receivingCountriesResponse.success || !receivingCountriesResponse.data.length) {
        const newFormikValues = { ...formik.values, sendingCountry: defaultSendingCountry };
        await formik.setValues(newFormikValues, false);
        setLoading(false);
        return;
      }
      const { defaultReceivingCountry } = getDefaultReceivingCountry(receivingCountriesResponse.data);

      const tariffsInfoResponse = await requests.tariffsInfo.mutate({
        sendingCountryId: defaultSendingCountry.id,
        receivingCountryId: defaultReceivingCountry.id,
        forTransferRepeat: false,
        paymentMethod: 'debitCard'
      });
      if (!tariffsInfoResponse.success || !tariffsInfoResponse.data.length) {
        const newFormikValues = { ...formik.values, sendingCountry: defaultSendingCountry, receivingCountry: defaultReceivingCountry };
        await formik.setValues(newFormikValues, false);
        setLoading(false);
        return;
      }
      const [currentInfoItem] = tariffsInfoResponse.data;
      setCurrentInfoItem(currentInfoItem);

      await requests.announcements.mutate({
        sendingCountryId: defaultSendingCountry.id,
        receivingCountryId: defaultReceivingCountry.id,
        receivingCurrencyId: currentInfoItem.receivingCurrency.id,
        process: 'sendingTransfer',
        event: 'directionSelected'
      });

      const newFormikValues = {
        ...formik.values,
        sendingCountry: defaultSendingCountry,
        receivingCountry: defaultReceivingCountry,
        sendingCurrency: currentInfoItem.sendingCurrency,
        receivingCurrency: currentInfoItem.receivingCurrency
      };
      await formik.setValues(newFormikValues, false);
      setLoading(false);
    };
    requestInitialData();
  }, []);

  return { loading };
};
