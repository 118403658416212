import type { FC } from 'react';
import React from 'react';
import classnames from 'classnames';

import { Label, ValidationMessage } from '@common/forms';

import styles from '../styles/Select.module.scss';
import dropdownStyles from '../styles/Dropdown.module.scss';
import optionStyles from '../styles/Options.module.scss';
import skeletonStyles from '../../../skeleton/Skeleton.module.scss';

import { useSelect } from './hooks/useSelect';

export interface SelectProps extends FieldProps {
  options: Option[];
  optionValue: Option;
  onChangeSelect: (option: Option) => void;
  noOptionsText: string;
  filterOption?: FilterOptionFunc;
  components?: {
    Option?: React.ComponentType<{ option: Option }>;
    SelectedValue?: React.ComponentType<{ option: Option }>;
  };
}

const defaultFilterOption: FilterOptionFunc = (option, inputValue) => (
  option.label.toLowerCase().includes(inputValue.toLowerCase())
);

export const Select: FC<SelectProps> = ({
  options,
  onChangeSelect,
  optionValue,
  noOptionsText,
  filterOption = defaultFilterOption,
  components,
  label,
  disabled,
  loading,
  isError = false,
  helperText
}) => {
  const { refs, functions, state } = useSelect({
    options,
    filterOption,
    optionValue,
    onChangeSelect
  });

  const optionItems = state.filteredOptions.map((option) => (
    <li
      key={option.id}
      role="option"
      aria-selected={optionValue?.id === option.id}
      aria-hidden="true"
      className={classnames(optionStyles.dropdown_option, {
        [optionStyles.selected]: optionValue?.id === option.id,
        [optionStyles.selected]: state.searchSelectedOption.id === option.id
      })}
      onClick={() => functions.onItemClick(option)}
    >
      <div className={optionStyles.option}>
        {components?.Option ? <components.Option option={option} /> : option.label}
      </div>
    </li>
  ));

  const showValidationMessage = !state.showOptions && isError && !!helperText;
  const showOption = !state.inputValue && !!optionValue;

  return (
    <div
      aria-hidden="true"
      className={styles.container}
      ref={refs.selectRef}
      onKeyDown={functions.onSelectKeyDown}
    >
      <div
        aria-hidden="true"
        onClick={() => {
          if (disabled || loading) return;
          functions.onSelectClick();
        }}
        className={classnames(styles.container_select, {
          [styles.error]: isError,
          [skeletonStyles.skeleton]: loading,
          [styles.disabled]: disabled
        })}
      >
        <div
          className={classnames(styles.option_container, {
            [styles.label_up]: !!optionValue
          })}
        >
          <input
            id="select"
            autoComplete="off"
            type="text"
            tabIndex={-1}
            disabled={disabled}
            className={styles.option_input}
            ref={refs.inputRef}
            value={state.inputValue}
            onChange={functions.searchInputHandler}
          />
          <Label label={label} />
          {showOption && (
            <div className={optionStyles.option}>
              {components?.SelectedValue ? (
                <components.SelectedValue option={optionValue!} />
              ) : (
                optionValue!.label
              )}
            </div>
          )}
        </div>
        <span className={classnames(styles.arrow, { [styles.open]: state.showOptions })} />
      </div>

      {state.showOptions && (
        <div className={dropdownStyles.container_select_options}>
          <ul ref={refs.ulRef} className={dropdownStyles.dropdown_content}>
            {!state.filteredOptions.length && (
              <div className={optionStyles.not_found}>
                {noOptionsText}
              </div>
            )}
            {optionItems}
          </ul>
        </div>
      )}
      {showValidationMessage && <ValidationMessage id="select" message={helperText} />}
    </div>
  );
};
