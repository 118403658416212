export default {
  /**
   * Reformat category data by article group
   * @param category
   * @param groups
   * @param {string} to
   * @param {string} titleName
   * @param {string} slugName
   * @param {string} catSlugName
   * @param groupName
   * @param articlesName
   * @returns {{groupedArticles: *, articles: *}}
   */
  reformatCategoryData(category, groups, to, titleName, slugName, catSlugName, groupName = 'articles_group', articlesName = 'articles') {
    const groupTitle = {};
    if (groups.length) {
      groups.forEach(group => {
        groupTitle[group.strapiId] = group[titleName];
      });
    }
    const groupedArticles = {};
    const articles = [];

    category[articlesName].forEach(article => {
      const articleData = {
        to: `${to}/${category[catSlugName]}/${article[slugName]}/`,
        title: article[titleName],
        slug: article[slugName],
        position: article.position || null
      };

      if (article[groupName]) {
        if (groupedArticles[groupTitle[article[groupName]]]) {
          groupedArticles[groupTitle[article[groupName]]].push(articleData);
        } else {
          groupedArticles[groupTitle[article[groupName]]] = [articleData];
        }
      } else {
        articles.push(articleData);
      }
    });
    return { groupedArticles, articles };
  },
  /**
   *
   * @param articles
   * @param {string} to
   * @param {string} catSlugName
   * @param {string} titleName
   * @param {string} slugName
   * @param {string} groupName
   * @param {string} categoryName
   * @returns {{groupedArticles: *, articles: *}}
   */
  formatAllCategories({
    articles = [],
    to = "",
    catSlugName,
    titleName,
    slugName,
    groupName = 'articles_group',
    categoryName = 'help_category'
  }) {
    const groupedArticles = {};
    const formatArticles = [];

    articles.forEach(article => {
      const articleData = {
        to: `${to}/${article[categoryName][catSlugName]}/${article[slugName]}/`,
        title: article[titleName],
        slug: article[slugName],
      };
      if (article[groupName]) {
        if (groupedArticles[article[groupName][titleName]]) {
          groupedArticles[article[groupName][titleName]].push(articleData);
        } else {
          groupedArticles[article[groupName][titleName]] = [articleData];
        }
      } else {
        formatArticles.push(articleData);
      }
    });
    return { groupedArticles, articles: formatArticles };
  },
};
