import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from 'axios';

import { SUPPORTED_LOCALES } from '@utils/constants';
import { isInvalidResponseStatus } from '@utils/helpers';
import { configuration } from '../../../configuration';

const { apiUrl } = configuration;

const CONTENT_TYPES = {
  JSON: 'json',
  PDF: 'pdf'
};

export class API {
  axios = Axios.create({
    validateStatus(status) {
      return status >= 200 && status < 505;
    }
  });

  request = this.axios;

  constructor(baseUrl: string) {
    this.axios.defaults.baseURL = baseUrl;
    this.axios.interceptors.request.use(this.requestSetHeaders);
    this.axios.interceptors.response.use(this.responseHandler, this.responseErrorHandler);
  }

  requestSetHeaders = (request: AxiosRequestConfig): AxiosRequestConfig => {
    if (!window) return request;
    const acceptLanguageHeader =
      SUPPORTED_LOCALES.find((locale) => window.location.href.includes(`/${locale}/`)) || 'ru';
    request.headers = { ...request.headers, 'accept-language': acceptLanguageHeader };
    return request;
  };

  responseHandler = (
    response: AxiosResponse & { success?: boolean }
  ): AxiosResponse & { success: boolean } => {
    // ✅ important:
    // Only 'json' and 'pdf' response type formats should to be successful.
    const isContentTypeValid =
      response.headers['content-type']?.includes(CONTENT_TYPES.JSON) ||
      response.headers['content-type']?.includes(CONTENT_TYPES.PDF);
    const isStatusValid = response.status !== 503;
    if (!isContentTypeValid && !isStatusValid) {
      const errorOfResponse = {
        ...response,
        error: { response: { text: 'Cannot connect to server' } }
      };
      return { ...errorOfResponse, success: false };
    }

    // ✅ important:
    // Handle error responses without 'message' and 'code' properties.
    if (
      (!response?.data?.message || !response?.data?.code) &&
      isInvalidResponseStatus(response.status)
    ) {
      const errorOfResponse = {
        ...response,
        error: { response: { text: 'Cannot connect to server' } }
      };
      return { ...errorOfResponse, success: false };
    }

    if (response.data && response.data.message && response.data.code) {
      return { ...response, success: false };
    }
    return { ...response, success: true };
  };

  responseErrorHandler = (error: AxiosError) => {
    const response = error.response ? { ...error, data: {} } : { error, data: {} };
    return { ...response, success: false };
  };
}

export const api = new API(apiUrl);
