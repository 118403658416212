import type { FC } from 'react';
import React from 'react';

import { useDisableScroll } from '@common/popups/Popup/hooks/useDisableScroll';

import styles from './Popup.module.scss';

export const Popup: FC<PopupProps> = ({ onClose, children }) => {
  useDisableScroll();

  return (
    <div className={styles.popup}>
      <div className={styles.popup_container}>
        <button className={styles.close} onClick={onClose} type="button" aria-label="close popup" />
        {children}
      </div>
      <div
        className={styles.popup_dark_screen}
        role="button"
        aria-hidden="true"
        onClick={onClose}
      />
    </div>
  );
};
