import React from 'react';
import styles from './downloadAppGrey.module.scss'
import cn from 'classnames'
import {useTranslation, Trans} from 'react-i18next'
import {appStoreLinks, googlePlayUrl}  from 'config/appLinks'

const DownloadAppGrey = ({size = null}) => {
    const {t, i18n} = useTranslation();
    const iconGPlayStyle = `gplay_${i18n.language}`;
    const iconAppStoreStyle = `appstore_${i18n.language}`;

    return (
        <article className={cn(styles['container'], 'group_margin', {[styles[`small`]]: size})}>
            <h2 className={styles['title']}>{t('footer_downloadApp_title')}</h2>
            <p className={styles['text']}>{t('footer_downloadApp_subTitle')}<br/>
                {t('footer_downloadApp_moneyTransfers')}
                {/*<span className={styles['label']}> {t('footer_downloadApp_withOutCommission')}</span>*/}
            </p>
            <div className={styles['link_wrapper']}>
                <div className={styles['app_links_wrapper']}>
                    <a className={cn(styles['store'], iconAppStoreStyle)} href={appStoreLinks[i18n.language]}>{t('footer_downloadApp_appStoreHrefTitle')}</a>
                    <a className={cn(styles['store'], iconGPlayStyle)} href={googlePlayUrl[i18n.language]}>{t('footer_downloadApp_googleHrefTitle')}</a>
                </div>
                <div className={styles['qr_code']} aria-hidden="true"> </div>
            </div>


            <div className={styles['img']} aria-hidden="true"> </div>
        </article>
    );
};

export default DownloadAppGrey;
