import React from 'react';
import classnames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import { MarketLinks } from '@common/MarketLinks/MarketLinks';
import { MarketPoints } from '@common/MarketPoints/MarketPoints';
import { OPERATING_SYSTEM } from '@utils/constants';

import styles from './BannerOld.module.scss';

export const BannerOld = () => {
  const { t } = useTranslation();

  return (
    <div className={classnames(styles.container, 'container')}>
      <div className={styles.banner} />
      <h1 className={styles.title}>
        {t('header_page_title_1')} {t('header_page_title_2')} {t('header_page_title_3')}
      </h1>
      <p className={styles.slogan}>
        <Trans i18nKey="header_subtitle_new" />
      </p>
      <ul className={styles.list}>
        <li className={styles.item}>{t('header_banner_advantage_1')}</li>
        <li className={styles.item}>{t('header_banner_advantage_2')}</li>
        <li className={styles.item}>{t('header_banner_advantage_3')}</li>
        <li className={styles.item}>{t('header_banner_advantage_4')}</li>
      </ul>
      <div className={styles.stores}>
        <div className={styles.store}>
          <MarketLinks operatingSystem={OPERATING_SYSTEM.ANDROID} />
          <MarketPoints operatingSystem={OPERATING_SYSTEM.ANDROID} />
        </div>
        <div className={styles.store}>
          <MarketLinks operatingSystem={OPERATING_SYSTEM.IOS} />
          <MarketPoints operatingSystem={OPERATING_SYSTEM.IOS} />
        </div>
      </div>
    </div>
  );
};
