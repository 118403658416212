import type { FC } from 'react';
import React from 'react';

import { MoneyLabel } from '@common/labels';
import { multiplyByHundred } from '@utils/helpers';

export const ExchangeLabel: FC<ExchangeLabelProps> = ({
  rate,
  direction,
  currencyTo,
  currencyFrom
}) => (
  <>
    {direction === 'direct' && (
      <>
        1
        {' '}
        {currencyTo}
        {' '}
        =
        {' '}
        <MoneyLabel amount={multiplyByHundred(rate)} currency={currencyFrom} />
      </>
    )}
    {direction === 'reverse' && (
      <>
        1
        {' '}
        {currencyFrom}
        {' '}
        =
        {' '}
        <MoneyLabel amount={multiplyByHundred(rate)} currency={currencyTo} />
      </>
    )}
  </>
);
