import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import { isMobile } from 'react-device-detect';

import { Popup } from '@common/popups';

import { SelectInformation } from './components/SelectInformation/SelectInformation';
import { WebsiteInformation } from './components/WebsiteInformation/WebsiteInformation';
import { MobileAppInformation } from './components/MobileAppInformation/MobileAppInformation';

interface TransferUnavailablePopupProps {
  countryIdForAppLink: string;
  onClose: () => void;
}

export const TransferUnavailablePopup: FC<TransferUnavailablePopupProps> = ({ countryIdForAppLink, onClose }) => {
  const [currentInformation, setCurrentInformation] = useState<'select' | 'website' | 'mobileApp'>('select');

  const globeOnClick = useCallback(() => {
    setCurrentInformation('website');
  }, []);
  const phoneOnClick = useCallback(() => {
    if (isMobile) {
      window.location.href = `https://applink.koronapay.com/transfer/${countryIdForAppLink}`;
      return;
    }
    setCurrentInformation('mobileApp');
  }, [isMobile, countryIdForAppLink]);

  return (
    <Popup onClose={onClose}>
      {currentInformation === 'select' && (
        <SelectInformation
          globeOnClick={globeOnClick}
          phoneOnClick={phoneOnClick}
        />
      )}
      {currentInformation === 'website' && (
        <WebsiteInformation />
      )}
      {currentInformation === 'mobileApp' && (
        <MobileAppInformation />
      )}
    </Popup>
  );
};
