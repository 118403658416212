import React from 'react';
import styles from './downloadAppRed.module.scss'
import cn from 'classnames'
import {useTranslation} from 'react-i18next'
import {appStoreLinks, googlePlayUrl}  from 'config/appLinks'

const DownloadAppRed = () => {
    const {t, i18n} = useTranslation();
    const iconGPlayStyle = `gplay_${i18n.language}`;
    const iconAppStoreStyle = `appstore_${i18n.language}`;
    return (
        <article className={cn(styles['container'], 'group_margin')}>
            <div className={styles['content']}>
                <h2 className={styles['title']}>{t('DownLoadApp_title')}</h2>
                <p className={styles['text']}>{t('DownLoadApp_subTitle')}</p>
                <div className={styles['store_wrapper']}>
                    <div className={styles['link_wrapper']}>
                        <a className={cn(styles['store'], iconAppStoreStyle)} href={appStoreLinks[i18n.language]}>{t('DownLoadApp_hrefAppStoreTitle')}</a>
                        <a className={cn(styles['store'], iconGPlayStyle)} href={googlePlayUrl[i18n.language]}>{t('DownLoadApp_hrefGooglePlayTitle')}</a>
                    </div>
                    <div className={styles['qr_code']} aria-hidden="true"> </div>
                </div>

            </div>
            <ul className={styles['list']}>
                <li>— {t('DownLoadApp_advantage_1')}</li>
                <li>— {t('DownLoadApp_advantage_2')}</li>
                <li>— {t('DownLoadApp_advantage_3')}</li>
                {/*<li>— {t('DownLoadApp_advantage_4')}</li>*/}
            </ul>
        </article>
    );
};

export default DownloadAppRed;
