import { api } from '@utils/api';
import { RECEIVING_METHODS } from '@utils/constants/methods/methods';

export const requestReceivingCountries = ({ sendingCountryId }: ReceivingCountriesReqGetParams) =>
  api.request.get<never, ApiResponse<TransferDirectionPoint[]>>(
    `/transfers/directions/points/receiving`,
    {
      params: {
        sendingCountryId,
        receivingMethod: RECEIVING_METHODS.CASH
      }
    }
  );
