import { convertToMoney, multiplyByHundred } from '@utils/helpers';
import type { CalculatorFormik, CalculatorValues } from '@components/calculator/hooks/useCalculatorFormik';
import type { TariffsRequest } from '@components/calculator/hooks/useCalculatorRequests';

export interface ChangeAmountCalculatorValues extends CalculatorValues {
  sendingCountry: Country;
  receivingCountry: Country;
  sendingCurrency: Currency;
  receivingCurrency: Currency;
}
export type ChangeAmountCalculatorFormik = CalculatorFormik<ChangeAmountCalculatorValues>;

interface ChangeAmountParams {
  formik: ChangeAmountCalculatorFormik;
  tariffs: TariffsRequest;
}

export const changeAmount = async (
  newAmount: string,
  amountKey: 'sendingAmount' | 'receivingAmount',
  { formik, tariffs }: ChangeAmountParams
) => {
  const tariffsResponse = await tariffs.mutate({
    sendingCountryId: formik.values.sendingCountry.id,
    receivingCountryId: formik.values.receivingCountry.id,
    sendingCurrencyId: formik.values.sendingCurrency.id,
    receivingCurrencyId: formik.values.receivingCurrency.id,
    [amountKey]: multiplyByHundred(+newAmount),
    paymentMethod: 'debitCard',
    receivingMethod: 'cash',
    paidNotificationEnabled: formik.values.paidNotification
  });
  if (!tariffsResponse.success || !tariffsResponse.data.length) {
    await formik.setFieldValue('sendingAmount', '');
    await formik.setFieldValue('receivingAmount', '');
    await formik.setFieldTouched('sendingAmount', false);
    await formik.setFieldTouched('receivingAmount', false);
    return;
  }
  // ✅ important:
  // validate form for delete previous errors
  const [usedTariff] = tariffsResponse.data;
  await formik.setFieldValue('sendingAmount', convertToMoney(usedTariff.sendingAmount).toString());
  await formik.setFieldValue('receivingAmount', convertToMoney(usedTariff.receivingAmount).toString());
  await formik.setFieldTouched('sendingAmount', true);
  await formik.setFieldTouched('receivingAmount', true);
  await formik.validateForm();
};
