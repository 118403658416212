import { api } from '@utils/api';

export const requestSendingCountries = (
  { receivingMethod = 'all' }: SendingCountriesReqGetParams = { receivingMethod: 'all' }
) =>
  api.request.get<never, ApiResponse<TransferDirectionPoint[]>>(
    '/transfers/directions/points/sending',
    {
      params: {
        receivingMethod
      }
    }
  );
