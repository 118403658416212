import React from 'react';
import styles from './geography.module.scss';
import { Svg } from 'components/common/SvgFlags';
import cn from 'classnames';
import Button from '../../../components/common/Button';
import { Trans, useTranslation } from 'react-i18next';
import CountryList from './CountryList';

const Geography = ({ location }) => {
    const [isShow, setIsShow] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const {t} = useTranslation();

    const onButtonClick = async () => {
        setIsLoading(true);
        await setTimeout(() => {
            setIsLoading(false);
            setIsShow(!isShow);
        }, 500);

    };

    const showFlags = isShow ? styles['show'] : styles['hide'];

    const showRussia = location && !location.pathname.includes('/en')

    return (
        <article className={styles['container']}>
            <h2 className={styles['title']}>{t('Geography_mainTitle')}</h2>
            <p className={cn(styles['subtitle'], 'text_medium_300')}>{t('Geography_subTitle')}</p>
            <div className={styles['map']}>
                <div className={'hidden_m'}>
                    <div className={styles['hints']}>
                        <div className={styles['hint_az']}>
                            <span className={styles['flag']}><Svg id='azerbaijan'/></span>
                            <span className={styles['desc']}>{t('Geography_300_in_Azerbaijan')}</span>
                        </div>
                        {showRussia &&
                          <div className={styles['hint_rus']}>
                            <span className={ styles['flag']}><Svg id='russia'/></span>
                            <span className={styles['desc']}>{t('Geography_300_in_Russia')}</span>
                          </div>
                        }

                        <div className={styles['hint_kz']}>
                            <span className={styles['flag']}><Svg id='kazakhstan'/></span>
                            <span className={styles['desc']}>{t('Geography_300_in_Kazakhstan')}</span>
                        </div>
                        <div className={styles['hint_uz']}>
                            <span className={styles['flag']}><Svg id='uzbekistan'/></span>
                            <span className={styles['desc']}>{t('Geography_300_in_Uzbekistan')}</span>
                        </div>
                    </div>
                    <div className={styles['bubble_az']}><div className={styles['bubble']} /></div>
                    {showRussia && <div className={styles['bubble_rus']}><div className={styles['bubble']} /></div>}
                    <div className={styles['bubble_kz']}><div className={styles['bubble']} /></div>
                    <div className={styles['bubble_uz']}><div className={styles['bubble']} /></div>
                    <div className={styles['lines']}>
                        <svg width="474" height="223" viewBox="0 0 474 223" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path className={styles['line_kz']} d="M38 149C53.3333 100.333 116.8 3 248 3C379.2 3 439.333 90 453 133.5" stroke="url(#paint0_linear)" strokeWidth="2" strokeLinecap="round"/>
                            <path className={styles['line_az']} d="M1 199C21.5 178.667 75.5 138 160 138C249.5 138 301.167 181 323 202.5" stroke="url(#paint1_linear)" strokeWidth="2" strokeLinecap="round"/>
                            <path className={styles['line_uz']} d="M88 122.5C112.5 104.833 183.8 74.3 273 93.5C362.2 112.7 405.5 164.167 416 187.5" stroke="url(#paint2_linear)" strokeWidth="2" strokeLinecap="round"/>
                            {showRussia && <path className={styles['line_rus']} d="M15 97.0024C22 78.0033 37.4053 24.2081 115.5 6.00132C197 -12.9994 254 27.5049 264 47.0024" stroke="url(#paint3_linear)" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>}
                            <defs>
                                <filter id="filter0_d" x="244" y="26" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <filter id="filter1_d" x="396" y="168" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <filter id="filter2_d" x="434" y="116" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <filter id="filter3_d" x="303" y="183" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                                    <feOffset/>
                                    <feGaussianBlur stdDeviation="5"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                                </filter>
                                <linearGradient id="paint0_linear" x1="191" y1="6.49999" x2="12" y2="158" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stopColor="#448AFF"/>
                                    <stop offset="1" stopColor="#E4EEFF" stopOpacity="0"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear" x1="145.5" y1="127" x2="-8.5" y2="207" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.0232558" stopColor="#448AFF"/>
                                    <stop offset="1" stopColor="#E4EEFF" stopOpacity="0"/>
                                </linearGradient>
                                <linearGradient id="paint2_linear" x1="305" y1="82.5" x2="88" y2="122" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stopColor="#448AFF"/>
                                    <stop offset="1" stopColor="#E4EEFF" stopOpacity="0"/>
                                </linearGradient>
                                <linearGradient id="paint3_linear" x1="163.5" y1="-0.499992" x2="24" y2="88.5" gradientUnits="userSpaceOnUse">
                                    <stop offset="0" stopColor="#448AFF"/>
                                    <stop offset="1" stopColor="#E4EEFF"/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <div className={styles['for_ie']}>
                            <div className={styles['box_az_1']} />
                            <div className={styles['box_az_2']} />

                            {showRussia &&
                              <>
                                <div className={styles['box_rus_1']} />
                                <div className={styles['box_rus_2']} />
                              </>
                            }


                            <div className={styles['box_kz_1']} />
                            <div className={styles['box_kz_2']} />

                            <div className={styles['box_uz_1']} />
                            <div className={styles['box_uz_2']} />
                        </div>
                    </div>
                </div>
            </div>
            <ul className={cn(styles['list'], styles['list_hide'], showFlags)}>
                <CountryList />
                <li className={styles['item']}><span className={styles['item_flag']} /> </li>
            </ul>
            <div className={styles['btn']}>
                <Button
                    href={false}
                    name={isShow ? <Trans i18nKey={"Geography_hideList"} /> : <Trans i18nKey={"Geography_showList"} />}
                    color={'blue_border'}
                    size={'medium'}
                    onClick={onButtonClick}
                    loading={isLoading}
                />
            </div>

        </article>
    );
};

export default Geography;
