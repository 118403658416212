import type { CalculatorFormik, CalculatorValues } from '@components/calculator/hooks/useCalculatorFormik';
import type {
  ReceivingCountriesRequest, TariffsInfoRequest, TariffsRequest, AnnouncementsRequest
} from '@components/calculator/hooks/useCalculatorRequests';
import { resolveNewInfoItem, validateAmountLimit } from '@utils/helpers/calculator';
import { convertToMoney, multiplyByHundred } from '@utils/helpers/money/money';
import { getDefaultReceivingCountry } from '@utils/helpers/selectors';

export interface ChangeSendingCountryCalculatorValues extends CalculatorValues {
  sendingCountry: Country;
}
export type ChangeSendingCountryCalculatorFormik = CalculatorFormik<ChangeSendingCountryCalculatorValues>;

interface ChangeSendingCountryParams {
  formik: ChangeSendingCountryCalculatorFormik;
  receivingCountries: ReceivingCountriesRequest;
  tariffsInfo: TariffsInfoRequest;
  tariffs: TariffsRequest;
  announcements: AnnouncementsRequest;
  setCurrentInfoItem: (infoItem: TransferTariffInfo) => void;
}

export const changeSendingCountry = async (
  newSendingCountry: Country,
  {
    formik,
    receivingCountries,
    tariffs,
    tariffsInfo,
    announcements,
    setCurrentInfoItem
  }: ChangeSendingCountryParams
) => {
  const oldSendingCountry = formik.values.sendingCountry;
  if (newSendingCountry.id === oldSendingCountry.id) return;

  await formik.setErrors({});
  await formik.setTouched({});
  await formik.setFieldValue('sendingCountry', newSendingCountry);
  await formik.setFieldTouched('sendingCountry', true);

  const receivingCountriesResponse = await receivingCountries.mutate({
    sendingCountryId: newSendingCountry.id
  });
  if (!receivingCountriesResponse.success) {
    await formik.setFieldValue('sendingCountry', oldSendingCountry);
    return;
  }

  if (!formik.values.receivingCountry) return;
  const oldReceivingCountry = formik.values.receivingCountry;
  const oldReceivingCountryInNewCountries = receivingCountriesResponse.data.find((directionPoint) => directionPoint.country.id === oldReceivingCountry.id)?.country;
  const newReceivingCountry = oldReceivingCountryInNewCountries ?? getDefaultReceivingCountry(receivingCountriesResponse.data).defaultReceivingCountry;
  await formik.setFieldValue('receivingCountry', newReceivingCountry);
  await formik.setFieldTouched('receivingCountry', true);

  const tariffsInfoResponse = await tariffsInfo.mutate({
    sendingCountryId: newSendingCountry.id,
    receivingCountryId: newReceivingCountry.id,
    forTransferRepeat: false,
    paymentMethod: 'debitCard'
  });
  if (!tariffsInfoResponse.success) {
    await formik.setFieldValue('sendingCountry', oldSendingCountry);
    await formik.setFieldValue('receivingCountry', oldReceivingCountry);
    return;
  }
  if (!tariffsInfoResponse.data.length) {
    await formik.setFieldValue('sendingCurrency', null);
    await formik.setFieldValue('receivingCurrency', null);
    await formik.setFieldValue('sendingAmount', '');
    await formik.setFieldValue('receivingAmount', '');
    await formik.setFieldValue('paidNotification', false);
    return;
  }

  const { newInfoItem } = resolveNewInfoItem(
    tariffsInfoResponse.data,
    formik.values.sendingCurrency?.id,
    formik.values.receivingCurrency?.id
  );
  setCurrentInfoItem(newInfoItem);
  await formik.setFieldValue('sendingCurrency', newInfoItem.sendingCurrency);
  await formik.setFieldValue('receivingCurrency', newInfoItem.receivingCurrency);

  await announcements.mutate({
    sendingCountryId: newSendingCountry.id,
    receivingCountryId: newReceivingCountry.id,
    receivingCurrencyId: newInfoItem.receivingCurrency.id,
    process: 'sendingTransfer',
    event: 'directionSelected'
  });

  const isSendingAmountValid = !!formik.values.sendingAmount && validateAmountLimit({
    amount: multiplyByHundred(+formik.values.sendingAmount),
    amountKey: 'sendingAmount',
    currentInfoItem: newInfoItem
  });
  const isReceivingAmountValid = !!formik.values.receivingAmount && validateAmountLimit({
    amount: multiplyByHundred(+formik.values.receivingAmount),
    amountKey: 'receivingAmount',
    currentInfoItem: newInfoItem
  });
  if (!isSendingAmountValid || !isReceivingAmountValid) {
    if (formik.values.sendingAmount && formik.values.receivingAmount) await formik.validateForm();
    return;
  }

  const tariffsResponse = await tariffs.mutate({
    sendingCountryId: newSendingCountry.id,
    receivingCountryId: newReceivingCountry.id,
    sendingCurrencyId: newInfoItem.sendingCurrency.id,
    receivingCurrencyId: newInfoItem.receivingCurrency.id,
    receivingAmount: multiplyByHundred(+formik.values.receivingAmount),
    paymentMethod: 'debitCard',
    receivingMethod: 'cash',
    paidNotificationEnabled: formik.values.paidNotification
  });
  if (!tariffsResponse.success) {
    await formik.setFieldValue('sendingAmount', '');
    await formik.setFieldValue('receivingAmount', '');
    await formik.setFieldTouched('sendingAmount', false);
    await formik.setFieldTouched('receivingAmount', false);
    return;
  }
  // ✅ important:
  // validate form for delete previous errors
  const [usedTariff] = tariffsResponse.data;
  await formik.setFieldValue('sendingAmount', convertToMoney(usedTariff.sendingAmount).toString());
  await formik.setFieldValue('receivingAmount', convertToMoney(usedTariff.receivingAmount).toString());
  await formik.setFieldTouched('sendingAmount', true);
  await formik.setFieldTouched('receivingAmount', true);
  await formik.validateForm();
};
