import styles from './geography.module.scss';
import { Svg } from '../../../components/common/SvgFlags';
import React from 'react';
import { useTranslation } from 'react-i18next';
import languageLocales from 'locales/CountryList';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';

/**
 * Сопоставлениея кода страны и флага
 * @type {{}}
 */
export const languageObject = [
{ id: 'at', flag: 'austria', name: 'Austria'},
{ id: 'bg', flag: 'bulgary', name: 'Bulgaria'},
{ id: 'cy', flag: 'cyprus', name: 'Cyprus'},
{ id: 'cz', flag: 'czech', name: 'Czechia'},

{ id: 'de', flag: 'germany', name: 'Germany'},
{ id: 'dk', flag: 'denmark', name: 'Denmark'},
{ id: 'es', flag: 'spain', name: 'Spain'},

{ id: 'ee', flag: 'estonia', name: 'Estonia'},
{ id: 'fi', flag: 'finland', name: 'Finland'},
{ id: 'fr', flag: 'france', name: 'France'},

{ id: 'uk', flag: 'britain', name: 'Britain'},
{ id: 'gr', flag: 'greece', name: 'Greece'},
{ id: 'hr', flag: 'croatia', name: 'Croatia'},

{ id: 'hu', flag: 'hungary', name: 'Hungary'},
{ id: 'ie', flag: 'ireland', name: 'Ireland'},
{ id: 'it', flag: 'italy', name: 'Italy'},

{ id: 'li', flag: 'liechtenstein', name: 'Liechtenstein'},
{ id: 'lt', flag: 'lithuania', name: 'Lithuania'},
{ id: 'lv', flag: 'latvia', name: 'Latvia'},

{ id: 'mt', flag: 'malta', name: 'Malta'},
{ id: 'no', flag: 'norway', name: 'Norway'},
{ id: 'pl', flag: 'poland', name: 'Poland'},

{ id: 'pt', flag: 'portugal', name: 'Portugal'},
{ id: 'sk', flag: 'slovakia', name: 'Slovakia'},

{ id: 'si', flag: 'slovenia', name: 'Slovenia'},
{ id: 'se', flag: 'sweden', name: 'Sweden'},
{ id: 'az', flag: 'azerbaijan', name: 'Azerbaijan'},

{ id: 'by', flag: 'belarusian', name: 'Belarus'},
{ id: 'vn', flag: 'vietnam', name: 'Vietnam'},
{ id: 'ge', flag: 'georgia', name: 'Georgia'},

{ id: 'il', flag: 'israel', name: 'Israel'},
{ id: 'jo', flag: 'jordan', name: 'Jordan'},
{ id: 'kz', flag: 'kazakhstan', name: 'Kazakhstan'},
{ id: 'ua', flag: 'ukraine', name: 'Ukraine'},

{ id: 'kg', flag: 'kirghizstan', name: 'Kyrgyzstan'},
{ id: 'ma', flag: 'morocco', name: 'Morocco'},
{ id: 'md', flag: 'moldova', name: 'Moldova'},

{ id: 'ru', flag: 'russia', name: 'Russia'},
{ id: 'tr', flag: 'turkey', name: 'Turkey'},
{ id: 'uz', flag: 'uzbekistan', name: 'Uzbekistan'},
];

const addTranslation = (i18n) => {
    const currentLangId = i18n.language;
    const langById = keyBy(languageLocales, 'id');
    languageObject.map(country => {
        country.translation = get(langById, [country.id, "title", currentLangId], '');
    })
    return languageObject.sort((a,b) => (a.translation > b.translation ? 1 : -1))
}

export default () => {
    const {i18n} = useTranslation();
    addTranslation(i18n);
    const countries = i18n.language === 'en' ? languageObject.filter(el => el.id !== 'ru') : languageObject
    return countries.map(country => {
        return (
            <li key={country.flag} className={styles['item']}>
                <span className={styles['item_flag']} id={country.id}>
                    <Svg id={country.flag}/>
                </span>{country.translation}
            </li>
    )});

}
