import type { FC } from 'react';
import React from 'react';

import { Select } from '@common/fields';
import { includesByDifLangLayout } from '@utils/helpers';
import { Flag } from '@common/svg';

import styles from '../styles/Select.module.scss';

interface OptionProps {
  option: Option;
}

const convertCountryToOption = (country: Country | null) => {
  if (!country) return;
  return {
    label: country.name,
    id: country.id,
    value: country
  };
};

export const CountryOption: FC<OptionProps> = ({ option }) => (
  <>
    <span className={styles.flag}>
      <Flag id={option.id.toLowerCase()} />
    </span>
    {option.label}
  </>
);

export const CountriesSelect: FC<any> = ({
  countries,
  changeCountry,
  selectedCountry,
  disabled,
  ...props
}) => {
  const options: Option[] = countries.map((el: TransferDirectionPoint) =>
    convertCountryToOption(el.country)
  );

  return (
    <Select
      optionValue={convertCountryToOption(selectedCountry)}
      onChangeSelect={(option) => {
        changeCountry(option.value);
      }}
      components={{ Option: CountryOption, SelectedValue: CountryOption }}
      {...props}
      filterOption={(option, inputValue) => includesByDifLangLayout(option.label, inputValue, 'ru')}
      options={options}
      disabled={disabled}
    />
  );
};
