// ✅ important:
// used second 'filter' method for get only unique sending currencies
export const getSendingCurrencies = (info: TransferTariffInfo[], receivingMethod: ReceivingMethod) => {
  if (!info.length) return { sendingCurrencies: [] };
  return {
    sendingCurrencies: info
      .filter((infoItem) => infoItem.receivingMethod === receivingMethod)
      .map((infoItem) => infoItem.sendingCurrency)
      .filter((currency, index, array) => array.findIndex((curr) => curr.id === currency.id) === index)
  };
};

// ✅ important:
// used second 'filter' method for get only unique receiving currencies
export const getReceivingCurrencies = (info: TransferTariffInfo[], sendingCurrency: Currency | null, receivingMethod: ReceivingMethod) => {
  if (!info.length || !sendingCurrency) return { receivingCurrencies: [] };
  return {
    receivingCurrencies: info
      .filter((infoItem) => infoItem.sendingCurrency.id === sendingCurrency.id && infoItem.receivingMethod === receivingMethod)
      .map((infoItem) => infoItem.receivingCurrency)
      .filter((currency, index, array) => array.findIndex((curr) => curr.id === currency.id) === index)
  };
};
