const MIN_LENGTH_FOR_THOUSAND_SEPARATOR = 5;

export const formatMoney = (newValue: string, oldValue: string, allowedPennies: boolean) => {
  const valuePartsRegexp = allowedPennies ? /^([1-9]\d*)+(\.\d{0,2})?$/ : /^([1-9]\d*)+$/;
  const valuePartsMatches = newValue.match(valuePartsRegexp);
  if (!valuePartsMatches) return oldValue;

  const [fullValue, valueIntegerPart, valueDecimalPart] = valuePartsMatches;
  if (valueIntegerPart.length < MIN_LENGTH_FOR_THOUSAND_SEPARATOR) return fullValue;

  const formattedRegexp = /\B(?=(\d{3})+(?!\d))/g;
  const valueFormattedIntegerPart = valueIntegerPart.replace(formattedRegexp, ' ');
  return valueDecimalPart ? valueFormattedIntegerPart + valueDecimalPart : valueFormattedIntegerPart;
};

export const removeFormattingMoney = (value: string) => {
  const unformattedRegexp = /\s/g;
  const unformattedValue = value.replace(unformattedRegexp, '');
  return unformattedValue;
};
