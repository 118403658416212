export const getWarningsPriority0 = (
  infoItem: TransferTariffInfo | undefined,
  receivingCurrencyId: string | undefined
) => {
  if (!infoItem?.warnings || !receivingCurrencyId) return { warningsPriority0: null };

  const warnings = infoItem.warnings.find((warningItem) => warningItem.priority === 0);
  return { warningsPriority0: warnings?.messages ?? null };
};

export const getWarningPriority1 = (
  infoItem: TransferTariffInfo | undefined,
  receivingCurrencyId: string | undefined
) => {
  if (!infoItem?.warnings || !receivingCurrencyId) return { warningPriority1: null };

  const warning = infoItem.warnings.find((warningItem) => warningItem.priority === 1);
  if (!warning?.messages) return { warningPriority1: null };

  const [warningMessage] = warning.messages;
  const [warningImageUrl] = warning.imageUrls || [];
  return { warningPriority1: { ...warningMessage, ...(warningImageUrl && { imageUrl: warningImageUrl }) } };
};
