import type { FC } from 'react';
import React from 'react';

import { convertToMoney } from '@utils/helpers';

const MAX_MONEY_PRECISION = 4;

export const MoneyLabel: FC<MoneyLabelProps> = ({ amount, currency, signed = false }) => {
  const sign = signed && amount > 0 ? '+' : '';
  const value = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    maximumFractionDigits: MAX_MONEY_PRECISION
  }).format(convertToMoney(amount));
  const str = `${sign}${value}`;

  return <span>{str}</span>;
};
