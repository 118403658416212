import React, { useState, useEffect, useRef } from 'react';
import styles from './howtosteps.module.scss'
import cn from 'classnames'
import {useTranslation, Trans} from "react-i18next";
import { debounce } from 'lodash';

import ru_step_1 from '../../../assets/images/steps/ru/step-1.png';
import ru_step_2 from '../../../assets/images/steps/ru/step-2.png';
import ru_step_3 from '../../../assets/images/steps/ru/step-3.png';
import ru_step_4 from '../../../assets/images/steps/ru/step-4.png';

import en_step_1 from '../../../assets/images/steps/en/step-1.png'
import en_step_2 from '../../../assets/images/steps/en/step-2.png'
import en_step_3 from '../../../assets/images/steps/en/step-3.png'
import en_step_4 from '../../../assets/images/steps/en/step-4.png'

import de_step_1 from '../../../assets/images/steps/en/step-1.png'
import de_step_2 from '../../../assets/images/steps/en/step-2.png'
import de_step_3 from '../../../assets/images/steps/en/step-3.png'
import de_step_4 from '../../../assets/images/steps/en/step-4.png'

const BASE_URL = 'https://koronapay.com/transfers/europe';

const imageMap = {
    ru: {
        step_1: ru_step_1,
        step_2: ru_step_2,
        step_3: ru_step_3,
        step_4: ru_step_4,
    },
    en: {
        step_1: en_step_1,
        step_2: en_step_2,
        step_3: en_step_3,
        step_4: en_step_4,
    },
    de: {
        step_1: de_step_1,
        step_2: de_step_2,
        step_3: de_step_3,
        step_4: de_step_4,
    },
};

const useRect = () => {
    //узнаем расстояние от блоков перехода до левого верхнего угла окна при ресайзе страницы
    const ref = useRef();
    const [rect, setRect] = useState({});

    const setRectValue = debounce(() => setRect(ref && ref.current ? ref.current.getBoundingClientRect() : {}), 100);

    useEffect(() => {
        setRectValue();

        window.addEventListener('resize', setRectValue);

        return () => window.removeEventListener('resize', setRectValue);
    }, []);


    return [rect, ref];
};

const useHeight = () => {
    //узнаем высоту окна при ресайзе
    const [height, setHeight] = useState();

    const setWindowHeight = debounce(() => setHeight(window.innerHeight), 100);

    useEffect(() => {
        setWindowHeight();

        window.addEventListener('resize', setWindowHeight);

        return () => window.removeEventListener('resize', setWindowHeight);
    }, []);

    return height;
};

const useInitialScroll = () => {
    //узнаем позицию скрола на момент ресайза страницы
    const [initialScroll, setInitialScroll] = useState(0);

    const setInitialWindowScroll = debounce(() => { setInitialScroll(window.scrollY) }, 100);

    useEffect(() => {
        setInitialWindowScroll();

        window.addEventListener('resize', setInitialWindowScroll);

        return () => window.removeEventListener('resize', setInitialWindowScroll);
    }, []);

    return initialScroll;
};


const HowToSteps = () => {
    const {t, i18n} = useTranslation();

    //расстояние от блока до левого верхнего угла окна
    const [step1Rect, step1Ref] = useRect();
    const [step2Rect, step2Ref] = useRect();
    const [step3Rect, step3Ref] = useRect();
    const [step4Rect, step4Ref] = useRect();

    //высота окна
    const height = useHeight();
    //позиция скрола на момент загрузки
    const initialScroll = useInitialScroll();

    const [flyBoxClass, setFlyBoxClass] = useState();
    const [flyBoxImageIndex, setFlyBoxImageIndex] = useState(0);

    const [currentScrollPosition, setCurrentScrollPosition] = useState(0);

    useEffect(() => {
        updateFlyBoxClass(initialScroll);
        updateFlyBoxImage(initialScroll);
    }, [initialScroll]);

    useEffect(() => {
        updateFlyBoxClass(currentScrollPosition);
        updateFlyBoxImage(currentScrollPosition);
    }, [currentScrollPosition]);

    const updateFlyBoxClass = (position) => {
        let newFlyBoxClass;

        //координаты середины блока перехода = расстояние от левого верхнего угла окна до начала блока + позиция скрола на момент загрузки + половина высоты блока
        // const fixedPoint = position.y + step1Rect.top + (step1Rect.height/2);
        const fixedPoint = step1Rect.top + initialScroll + (step1Rect.height/2);
        //const bottomPoint = position.y + step4Rect.top + (step4Rect.height/2);
        const bottomPoint = step4Rect.top + initialScroll + (step4Rect.height/2);

        //координаты середины страницы = позиция текущего скролла + половины высоты экрана
        const middleWindow = position + (height/2);

        // console.log('начало '+ fixedPoint);
        // console.log('конец '+ bottomPoint);
        // console.log('середина окна '+ middleWindow);

        //выясняем когда телефон прилипает и отлипает
        if (middleWindow >= fixedPoint && middleWindow < bottomPoint) {
            newFlyBoxClass = 'fixed'
        } else if (middleWindow >= bottomPoint) {
            newFlyBoxClass = 'bottom';
        } else {
            newFlyBoxClass = undefined;
        }

        if (newFlyBoxClass === flyBoxClass) return;
        setFlyBoxClass(newFlyBoxClass);
    };

    const updateFlyBoxImage = (position) => {
        let newFlyBoxImageIndex;

        //координаты смены изображений = расстояние от блока до позиции скролла + позиция скрола -  половина высоты блока - смещение
        const secondImage = step2Rect.top + initialScroll - step2Rect.height/2 - 100;
        const thirdImage = step3Rect.top + initialScroll - step3Rect.height/2 - 100;
        const forthImage = step4Rect.top + initialScroll - step4Rect.height/2 - 100;

        //console.log('вторая картинка '+ secondImage);
        //console.log('третья картинка '+ thirdImage);
        //console.log('последняя картинка '+ forthImage);
        //console.log('scroll ' + position);

        if (position < secondImage) {
            newFlyBoxImageIndex = 0;
        } else if (position > secondImage && position < thirdImage) {
            newFlyBoxImageIndex = 1;
        } else if (position > thirdImage && position < forthImage) {
            newFlyBoxImageIndex = 2;
        } else {
            newFlyBoxImageIndex = 3;
        }

        if (newFlyBoxImageIndex === flyBoxImageIndex) return;

        setFlyBoxImageIndex(newFlyBoxImageIndex);
    };



    return (
        <article className={cn(styles['container'], 'group_margin')}>
            <h2 className={styles['title']}><Trans i18nKey={'shortSendMoney_title'}/></h2>
            <div className={cn(styles['info'], 'group_margin')}>
                <div className={styles['info_item_1']}>
                    <h3 className={styles['info_title']}><Trans i18nKey={'shortSendMoney_step_1_header'}/></h3>
                    <p className={styles['info_desc']}><Trans i18nKey={'shortSendMoney_step_1_content'}/></p>
                    <span aria-hidden="true" className={styles['arrow_1']} />
                </div>
                <div className={styles['info_item_2']}>
                    <h3 className={styles['info_title']}><Trans i18nKey={'shortSendMoney_step_2_header'}/></h3>
                    <p className={styles['info_desc']}><Trans i18nKey={'shortSendMoney_step_2_content'}/></p>
                    <span aria-hidden="true" className={styles['arrow"2']} />
                </div>
                <div className={styles['info_item_3']}>
                    <h3 className={styles['info_title']}><Trans i18nKey={'shortSendMoney_step_3_header'}/></h3>
                    <p className={styles['info_desc']}><Trans i18nKey={'shortSendMoney_step_3_content'}/></p>
                </div>
            </div>
            <div className={styles['content']} itemScope itemType="http://schema.org/HowTo">
                <h2 className={styles['title_2']}><strong><span itemProp="name"><Trans i18nKey={'HowToSendMoney_mainTitle'}/></span></strong></h2>
                <div itemProp="image" className={cn(styles['fly_box'], flyBoxClass ? styles[flyBoxClass] : '')}>
                    <img id='img-1' alt={t('HowToSendMoney_01_title')} className={cn(styles['fly_img'], flyBoxImageIndex === 0 ? styles['visible'] : '')} src={imageMap[i18n.language].step_1}/>
                    <img id='img-2' alt={t('HowToSendMoney_02_title')} className={cn(styles['fly_img'], flyBoxImageIndex === 1 ? styles['visible'] : '')} src={imageMap[i18n.language].step_2}/>
                    <img id='img-3' alt={t('HowToSendMoney_03_title')} className={cn(styles['fly_img'], flyBoxImageIndex === 2 ? styles['visible'] : '')} src={imageMap[i18n.language].step_3}/>
                    <img id='img-4' alt={t('HowToSendMoney_04_title')} className={cn(styles['fly_img'], flyBoxImageIndex === 3 ? styles['visible'] : '')} src={imageMap[i18n.language].step_4}/>
                </div>
                <section ref={step1Ref} className={styles['step']}  id="step1" itemProp="step" itemScope itemType="http://schema.org/HowToStep">
                    <div className={styles['img_box']} itemProp="image"><img alt={t('HowToSendMoney_01_title')} className={styles['no_fly_img']} src={imageMap[i18n.language].step_1}/></div>
                    <h3 className={cn('like_h3', styles['subtitle'])} itemProp="name"><Trans i18nKey={'HowToSendMoney_01_title'}/></h3>
                    <div className={styles['desc']} itemProp="text">
                        <p className={styles['text']}>{t('HowToSendMoney_01_subtitle')}</p>
                        <ul className={styles['list']} >
                            <li>{t('HowToSendMoney_01_subparagraphs_1')}</li>
                            <li>{t('HowToSendMoney_01_subparagraphs_2')}</li>
                            <li>{t('HowToSendMoney_01_subparagraphs_3')}</li>
                            <li>{t('HowToSendMoney_01_subparagraphs_4')}</li>
                        </ul>
                        <p className={styles['text']}>{t('HowToSendMoney_01_description')}</p>
                        {/*<p className={styles['commission']}><span>0</span><Trans i18nKey={'HowToSendMoney_01_commissionForTransfer'} /></p>*/}
                    </div>
                    <meta itemProp="url" content={`${BASE_URL}/#step1`} />
                    <meta itemProp="image" content={imageMap[i18n.language].step_1}/>
                </section>

                <section ref={step2Ref} className={styles['step']} id="step2" itemProp="step" itemScope itemType="http://schema.org/HowToStep">
                    <div className={styles['img_box']} itemProp="image"><img alt={t('HowToSendMoney_02_title')} className={styles['no_fly_img']} src={imageMap[i18n.language].step_2}/></div>
                    <h3 className={cn('like_h3', styles['subtitle'])} itemProp="name"><Trans i18nKey={'HowToSendMoney_02_title'} /></h3>
                    <div className={styles['desc']} itemProp="text">
                        <p className={styles['text']} ><Trans i18nKey={'HowToSendMoney_02_description'} /></p>
                    </div>
                    <meta itemProp="url" content={`${BASE_URL}/#step2`}/>
                    <meta itemProp="image" content={imageMap[i18n.language].step_2}/>
                </section>

                <section ref={step3Ref} className={styles['step']} id="step3" itemProp="step" itemScope itemType="http://schema.org/HowToStep">
                    <div className={styles['img_box']} itemProp="image"><img alt={t('HowToSendMoney_03_title')} className={styles['no_fly_img']} src={imageMap[i18n.language].step_3}/></div>
                    <h3 className={cn('like_h3', styles['subtitle'])} itemProp="name"><Trans i18nKey={'HowToSendMoney_03_title'} /></h3>
                    <div className={styles['desc']} itemProp="text">
                        <p className={styles['text']} ><Trans i18nKey={'HowToSendMoney_03_description'} /></p>
                    </div>

                    <meta itemProp="url" content={`${BASE_URL}/#step3`}/>
                    <meta itemProp="image" content={imageMap[i18n.language].step_3}/>
                </section>

                <section ref={step4Ref} className={styles['step']} id="step4" itemProp="step" itemScope itemType="http://schema.org/HowToStep">
                    <div className={styles['img_box']} itemProp="image"><img alt={t('HowToSendMoney_04_title')} className={styles['no_fly_img']} src={imageMap[i18n.language].step_4}/></div>
                    <h3 className={cn('like_h3', styles['subtitle'])} itemProp="name"><Trans i18nKey={'HowToSendMoney_04_title'} /></h3>
                    <div className={styles['desc']} itemProp="text" >
                        <p className={styles['text']}><Trans i18nKey={'HowToSendMoney_04_subtitle'} /></p>
                        <p className={styles['done']}><Trans i18nKey={'HowToSendMoney_04_description'} /></p>
                        <p className={styles['text']}><Trans i18nKey={'HowToSendMoney_04_footer'} /></p>
                    </div>

                    <meta itemProp="url" content={`${BASE_URL}/#step4`}/>
                    <meta itemProp="image" content={imageMap[i18n.language].step_4}/>
                </section>
            </div>

        </article>
    );
};

export default HowToSteps;
