import type { FC, HTMLProps } from 'react';
import React from 'react';

import styles from './ValidateMessage.module.scss';

interface ValidationMessageProps extends HTMLProps<HTMLDivElement> {
  message: string;
  id: string;
}

export const ValidationMessage: FC<ValidationMessageProps> = ({ message, id, ...props }) => (
  <div id={id} className={styles.validate_message} {...props}>
    {message}
  </div>
);
