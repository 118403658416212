import React, { useState, useEffect } from 'react';
import styles from './form.module.scss'
import Button from "components/common/Button";
import ReactSelectAdapter from "components/common/ReactSelectAdapter";
import cn from 'classnames'
import {Field as FieldFF, Form} from 'react-final-form'
import Field, { validator, composeValidators } from "/src/modules/Home/feedbackForm/formField"
import ClientCaptcha from "components/common/CapchaCanvas";
import { graphql, useStaticQuery } from "gatsby";

import { useTranslation , Trans} from 'react-i18next'
import axios from 'axios'
import Popup from "../../../components/common/Popup";

let options = [
    {value: 'theme1', label: 'Тема 1'},
    {value: 'theme2', label: 'Тема 2'},
    {value: 'theme3', label: 'Тема 3'}
];

const NOTIFICATION_TIME = 5 * 1000; //ms

const FeedackForm = () => {
    const [captchaCode, setCaptchaCode] = useState('');
    const [ questionOptions, setQuestionOptions ] = useState(options);
    const [ submitMessage, setSubmitMessage] = useState('');
    const [needUpdateCapcha, setUpdateCapcha] = useState(false);
    const {t, i18n} = useTranslation();
    const currentLang = i18n.language;
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [isOpenForm, setIsOpenForm] = useState(false);

    const onSubmit = async (values, form) => {
        const formatFormData = {
            service: values.service.value,
            thema: values.questionGroup.value,
            body: `Имя: ${values.firstName}, телефон: ${values.phone}, текст обращения: ${values.message}`,
            client_email: values.email
        };

        const bodyFormData = new FormData();
        Object.keys(formatFormData).forEach((key) => {
            bodyFormData.append(key, formatFormData[key])
        });

        try {
            const response = await axios({
                method: 'post',
                url: `https://koronapay.com/transfers/mob/projects/eu/mail2_1.aspx?lang=${currentLang}`,
                data: bodyFormData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
                setIsOpenPopup(true);
                response.status === 200
                    ? setSubmitMessage(t('FaqForm_success_submit_message'))
                    : setSubmitMessage(t('FaqForm_fail_submit_message'));
                return response.status
        } catch (e) {
            setIsOpenPopup(true);
            setSubmitMessage(t('FaqForm_fail_submit_message'));
        } finally {
            setUpdateCapcha(true)
        }
    };

    const gatsbyRepoData = useStaticQuery(graphql`
        query {
          themes(en: {}) {
            en
            ru
            de
          }
        }
      `);
    useEffect( () => {
        let optionsFromResp = gatsbyRepoData.themes[currentLang].map(option => {
            return {value: option, label:option}
        });
        setQuestionOptions(optionsFromResp)
    }, gatsbyRepoData);

    return (
        <>
        <Form
            onSubmit={onSubmit}
            validate={(value => value.capcha === captchaCode)}
            render={({handleSubmit, form, submitting, error}) => {
                return (
                    <form className={styles['form']} onSubmit={async (event) => {
                        const requestStatus = await handleSubmit(event, form);
                        requestStatus === 200 && form.restart();
                    }}>
                        <div className={styles['header']}>
                            <div className={'grid_2in1'}>
                                <legend>
                                    <h3 className={'like-h3'}>{t('FaqForm_title')}</h3>
                                    <p className={styles['subtitle']}>
                                        <Trans i18nKey={'FaqForm_subTitle'} />
                                    </p>
                                </legend>
                            </div>
                            <div className={'grid_3'}>
                                {!isOpenForm && <Button
                                    name={t('FaqForm_button_get_question')}
                                    color={'red'}
                                    size={'large'}
                                    onClick={() => setIsOpenForm(true)}
                                />}
                            </div>
                        </div>
                        <div className={cn(styles['wrapper'], {[styles['open']]: isOpenForm })}>
                            <div className={styles['container']}>
                                <div className={'grid_2in1'}>
                                    <label className={'field_title'}>{t('FaqForm_field_choseService')} <span className={'required'}> *</span></label>
                                    <FieldFF
                                        name="service"
                                        component={ReactSelectAdapter}
                                        options={[
                                            {value: '0', label: t('FaqForm_field_choseService_value_service_point')},
                                            {value: '1', label: t('FaqForm_field_choseService_value_molile_application')},
                                        ]}
                                        placeholder={t('FaqForm_field_choseService_placeholder')}
                                        validate={validator.required}
                                    />
                                </div>
                                <div className={'grid_2in1'}>
                                    <label className={'field_title'}>{t('FaqForm_field_askQuestion')} <span className={'required'}> *</span></label>
                                    <FieldFF
                                        name="questionGroup"
                                        component={ReactSelectAdapter}
                                        options={questionOptions}
                                        placeholder={t('FaqForm_field_askQuestion_placeholder')}
                                        validate={validator.required}
                                    />
                                </div>

                                <Field
                                    wrapperClassName={'grid_3'}
                                    title={t('FaqForm_field_sendNumberTitle')} type={'tel'} name={'transferNumber'}
                                    placeholder={t('FaqForm_field_sendNumberPlaceholder')}
                                    validate={composeValidators(validator.minMaxLength(8,14), validator.mustBeNumber)}
                                />

                                <Field
                                    wrapperClassName={'grid_3'}
                                    title={t('FaqForm_field_nameTitle')} type={'tel'} name={'firstName'}
                                    placeholder={t('FaqForm_field_namePlaceholder')}
                                    isRequiredLabel
                                    validate={validator.required}
                                />

                                <Field
                                    wrapperClassName={'grid_3'}
                                    title={t('FaqForm_field_emailTitle')} type={'email'} name={'email'}
                                    placeholder={t('FaqForm_field_emailPlaceholder')}
                                    isRequiredLabel
                                    validate={composeValidators(validator.required,validator.isEmail)}
                                />

                                <Field
                                    wrapperClassName={'grid_3'}
                                    title={t('FaqForm_field_phoneTitle')} type={'tel'} name={'phone'}
                                    placeholder={t('FaqForm_field_phonePlaceholder')}
                                />

                                <Field
                                    wrapperClassName={'grid_1'}
                                    title={t('FaqForm_field_messageTitle')} type={'text'} name={'message'}
                                    placeholder={t('FaqForm_field_messagePlaceholder')}
                                    isRequiredLabel
                                    validate={composeValidators(validator.required, validator.maxLength(256))}
                                />

                                <div className={'grid_3'}>
                                    <label className={'field_title no-field_title'}>{t('FaqForm_field_capchaTitle')}</label>
                                    <div className={styles['capcha']}>
                                        <ClientCaptcha
                                            needUpdateCapcha={needUpdateCapcha}
                                            setUpdateCapcha={setUpdateCapcha}
                                            captchaCode={setCaptchaCode}/>
                                    </div>
                                </div>

                                <Field
                                    wrapperClassName={'grid_3'}
                                    title={t('FaqForm_field_capchaTitle')} type={'tel'} name={'capcha'}
                                    placeholder={t('FaqForm_field_capchaPlaceholder')}
                                    isRequiredLabel
                                    validate={(composeValidators(validator.required, validator.capcha(captchaCode)))}
                                />

                                <div className={'grid_3'}>

                                    <label className={cn(styles['btn_offset'], 'field_title')}>&nbsp;</label>
                                    <Button
                                        type="submit"
                                        disabled={submitting}
                                        name={t('FaqForm_buttonName')}
                                        color={'red'}
                                        size={'large'}
                                    />
                                    <button type={'button'}  className={styles['close']} onClick={() => setIsOpenForm(false)}>
                                        {t('FaqForm_button_hide_form')}
                                    </button>


                                </div>

                                <p className={cn(styles['symbol'], 'text_extrasmall')}><span
                                    className={'required'}>*</span> {t('FaqForm_requiredFields')}</p>
                            </div>
                        </div>
                    </form>
                )}}
        />
            <Popup isOpen={isOpenPopup} onClosePopup={() => setIsOpenPopup(false)} closeOnClickOutside>
                <div>
                    <p>{submitMessage}</p>
                </div>
            </Popup>
        </>
    );
};

export default FeedackForm;
