import type { FC } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OPERATING_SYSTEM } from '@utils/constants';

import styles from './MarketPoints.module.scss';

interface MarketPointsProps {
  operatingSystem: 'android' | 'ios';
}

export const MarketPoints: FC<MarketPointsProps> = ({ operatingSystem }) => {
  const { t } = useTranslation();
  return (
    <>
      {operatingSystem === OPERATING_SYSTEM.IOS && (
        <p className={styles.rate}>
          <span className={styles.number}>{t('header_marketLink_appStoreRate')}</span>
          {t('header_marketLink_appStorePoints')}
        </p>
      )}
      {operatingSystem === OPERATING_SYSTEM.ANDROID && (
        <p className={styles.rate}>
          <span className={styles.number}>{t('header_marketLink_googlePlayRate')}</span>
          {t('header_marketLink_googlePlayPoints')}
        </p>
      )}
    </>
  );
};
