import { EURO_COUNTRIES } from '@utils/constants';

export const getEuropeanSendingCountries = (sendingCountries: TransferDirectionPoint[]) => {
  if (!sendingCountries) return { sendingCountries: [] };
  const euroCountries = sendingCountries.filter((el) => EURO_COUNTRIES.includes(el.country.id));
  return { sendingCountries: euroCountries };
};

export const getDefaultSendingCountry = (sendingCountries: TransferDirectionPoint[], geoip: $TSFixMe) => {
  const defaultSendingCountry = (sendingCountries.find((el) => el.country.id === geoip.countryIsoCode) ?? sendingCountries[0]).country;
  return { defaultSendingCountry };
};

export const getDefaultReceivingCountry = (receivingCountries: TransferDirectionPoint[]) => {
  const defaultReceivingCountry = receivingCountries[0].country;
  return { defaultReceivingCountry };
};
