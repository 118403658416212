import { useState } from 'react';

type Field = 'sendingCountry' | 'receivingCountry' | 'sendingCurrency' | 'receivingCurrency' | 'sendingAmount' | 'receivingAmount';
type Hierarchy = 0 | 1 | 2;

const FIELD_HIERARCHY: Record<Field, Hierarchy> = {
  sendingCountry: 0,
  receivingCountry: 1,
  sendingCurrency: 2,
  sendingAmount: 2,
  receivingCurrency: 2,
  receivingAmount: 2
};

export const useCalculatorLoading = () => {
  const [loading, setLoading] = useState(false);
  const [changeableField, setChangeableField] = useState<Field | undefined>(undefined);

  const checkFieldIsLoading = (calculatorField: Field) => {
    if (!loading || !changeableField) return false;

    // ✅ important:
    // Conditions need because currency and amount placed in shared input
    if (changeableField === 'sendingCurrency' || changeableField === 'sendingAmount') {
      if (calculatorField === 'sendingCurrency' || calculatorField === 'sendingAmount') return false;
      if (calculatorField === 'receivingCurrency' || calculatorField === 'receivingAmount') return true;
    }
    if (changeableField === 'receivingCurrency' || changeableField === 'receivingAmount') {
      if (calculatorField === 'receivingCurrency' || calculatorField === 'receivingAmount') return false;
      if (calculatorField === 'sendingCurrency' || calculatorField === 'sendingAmount') return true;
    }

    const changeableFieldHierarchy = FIELD_HIERARCHY[changeableField];
    const calculatorFieldHierarchy = FIELD_HIERARCHY[calculatorField];
    return changeableFieldHierarchy < calculatorFieldHierarchy;
  };
  const checkFieldIsDisabled = (calculatorField: Field) => {
    if (!loading || !changeableField) return false;

    // ✅ important:
    // Conditions need because currency and amount placed in shared input
    if (changeableField === 'sendingCurrency' || changeableField === 'sendingAmount') {
      if (calculatorField === 'sendingCurrency' || calculatorField === 'sendingAmount') return true;
      if (calculatorField === 'receivingCurrency' || calculatorField === 'receivingAmount') return false;
    }
    if (changeableField === 'receivingCurrency' || changeableField === 'receivingAmount') {
      if (calculatorField === 'receivingCurrency' || calculatorField === 'receivingAmount') return true;
      if (calculatorField === 'sendingCurrency' || calculatorField === 'sendingAmount') return false;
    }

    const changeableFieldHierarchy = FIELD_HIERARCHY[changeableField];
    const calculatorFieldHierarchy = FIELD_HIERARCHY[calculatorField];
    return changeableFieldHierarchy >= calculatorFieldHierarchy;
  };

  const createLoadingWrappedFn = <T extends AsyncPlainFunction>(fn: T, field: Field) => (
    async (...args: Parameters<T>) => {
      setLoading(true);
      setChangeableField(field);
      await fn(...args);
      setLoading(false);
      setChangeableField(undefined);
    }
  );

  return {
    loading,
    changeableField,
    checkFieldIsLoading,
    checkFieldIsDisabled,
    createLoadingWrappedFn
  };
};
