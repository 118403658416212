import type { KeyboardEvent, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';

import { useClickOutside } from '@utils/hooks';

interface useInputWithSelectParams {
  options: Option[];
  optionValue: Option | null;
  onChangeSelect: (option: Option) => void;
  externalRefs: {
    inputRef?: RefObject<HTMLInputElement>;
    selectRef?: RefObject<HTMLInputElement>;
    ulRef?: RefObject<HTMLUListElement>;
  }
}

export const useInputWithSelect = ({
  options,
  optionValue,
  onChangeSelect,
  externalRefs
}: useInputWithSelectParams) => {
  const internalSelectRef = useRef<HTMLInputElement>(null);
  const internalInputRef = useRef<HTMLInputElement>(null);
  const internalUlRef = useRef<HTMLUListElement>(null);

  const selectRef = externalRefs.selectRef ?? internalSelectRef;
  const inputRef = externalRefs.inputRef ?? internalInputRef;
  const ulRef = externalRefs.ulRef ?? internalUlRef;

  const [showOptions, setShowOptions] = useState(false);
  const [searchSelectedOption, setSearchSelectedOption] = useState({
    id: optionValue?.id,
    index: options.findIndex((option) => option.id === optionValue?.id)
  });

  // ✅ important:
  // Click outside select
  useClickOutside(
    selectRef,
    () => {
      const selectedOptionIndex = options.findIndex((el) => el.id === optionValue?.id);
      setSearchSelectedOption({ index: selectedOptionIndex, id: optionValue?.id });
      setShowOptions(false);
    },
    [optionValue?.id]
  );

  // ✅ important:
  // Scroll to selected option on open and key up/down
  useEffect(() => {
    if (ulRef.current && searchSelectedOption) {
      const optionHeight = ulRef.current.scrollHeight / options.length;
      ulRef.current.scrollTop = optionHeight * searchSelectedOption.index;
    }
  }, [ulRef, searchSelectedOption.id, showOptions]);

  const selectOption = (option: Option) => {
    const originalOptionIndex = options.findIndex((el) => el.id === option.id);
    setSearchSelectedOption({ id: option.id, index: originalOptionIndex });
    onChangeSelect(option);
    setShowOptions(false);
  };

  // ✅ important:
  // Logic for pressing keys up/down/enter for select
  const onSelectKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const firstOptionIndex = 0;
    const lastOptionIndex = options.length - 1;
    if (event.key === 'ArrowUp') {
      if (searchSelectedOption.index === firstOptionIndex) {
        const lastOption = options[lastOptionIndex];
        return setSearchSelectedOption({ id: lastOption.id, index: lastOptionIndex });
      }

      const upperOption = options[searchSelectedOption.index - 1];
      return setSearchSelectedOption({
        id: upperOption.id,
        index: searchSelectedOption.index - 1
      });
    }
    if (event.key === 'ArrowDown') {
      if (searchSelectedOption.index === lastOptionIndex) {
        const firstOption = options[firstOptionIndex];
        return setSearchSelectedOption({ id: firstOption.id, index: firstOptionIndex });
      }

      const lowerOption = options[searchSelectedOption.index + 1];
      return setSearchSelectedOption({
        id: lowerOption.id,
        index: searchSelectedOption.index + 1
      });
    }
    if (event.key === 'Enter') {
      const option = options[searchSelectedOption.index];
      selectOption(option);
    }
  };

  const onItemClick = (option: Option) => selectOption(option);

  const onSelectClick = () => setShowOptions(!showOptions);

  const onInputContainerClick = () => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  };

  return {
    refs: {
      selectRef,
      ulRef,
      inputRef
    },
    functions: {
      onSelectClick,
      onSelectKeyDown,
      onItemClick,
      onInputContainerClick
    },
    state: {
      showOptions,
      searchSelectedOption
    }
  };
};
