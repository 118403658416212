import type { FC } from 'react';
import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import { formatMoney, removeFormattingMoney } from '@utils/helpers/money/formatMoney';

interface NumberInputProps {
  value: string;
  onChange: (value: string) => void;
  allowedPennies?: boolean;
}

export const NumberInput: FC<NumberInputProps> = forwardRef<HTMLInputElement, NumberInputProps>(({
  value,
  onChange,
  allowedPennies = false,
  ...props
}, ref) => (
  <NumberFormat
    maxLength={15}
    getInputRef={ref}
    value={value}
    allowNegative={false}
    allowLeadingZeros={false}
    format={(unformattedValue) => formatMoney(unformattedValue, value, allowedPennies)}
    removeFormatting={(formattedValue) => removeFormattingMoney(formattedValue)}
    onValueChange={({ value }, { source }) => {
      if (source === 'event') onChange(value);
    }}
    {...props}
  />
));
