import type { FormikConfig } from 'formik';
import { useFormik } from 'formik';

export interface CalculatorValues {
  sendingCountry: Country | null;
  receivingCountry: Country | null;
  sendingCurrency: Currency | null;
  receivingCurrency: Currency | null;
  sendingAmount: string;
  receivingAmount: string;
  paidNotification: boolean;
}

export type CalculatorFormik<T extends CalculatorValues = CalculatorValues> = CustomFormik<T>;

export const useCalculatorFormik = ({
  initialValues, validationSchema, onSubmit, ...params
}: FormikConfig<CalculatorValues>): CalculatorFormik => (
  useFormik<CalculatorValues>({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit,
    ...params
  })
);
