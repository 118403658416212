import { useLayoutEffect } from 'react';

const handler = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();
};

export const useDisableScroll = () => {
  useLayoutEffect(() => {
    document.body.classList.add('noscrollpage');
    window.addEventListener('scroll', handler);
    return () => {
      document.body.classList.remove('noscrollpage');
      window.removeEventListener('scroll', handler);
    };
  }, []);
};
