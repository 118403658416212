import type { FC } from 'react';
import React from 'react';

import { Popup } from '@common/popups';

import styles from './WarningPopup.module.scss';

export const WarningPopup: FC<WarningPopupProps> = ({ warning, onClose }) => (
  <Popup onClose={onClose}>
    {warning?.imageUrl && (
      <img role="presentation" className={styles.image} src={warning.imageUrl} alt="" />
    )}
    {warning?.caption && <h3 className={styles.title}>{warning.caption}</h3>}
    {warning?.body && <p className={styles.desc}>{warning.body}</p>}
  </Popup>
);
