export const getPopupAnnouncement = (announcements: TransferAnnouncement[]) => {
  if (!announcements.length) return { popupAnnouncement: null };

  const popupAnnouncement = announcements.find((announcement) => announcement.displayMode === 'popup') ?? null;
  return { popupAnnouncement };
};

export const getInPlaceAnnouncements = (announcements: TransferAnnouncement[]) => {
  if (!announcements.length) return { inPlaceAnnouncements: null };

  const inPlaceAnnouncements = announcements.filter((announcement) => announcement.displayMode === 'inplace');
  return { inPlaceAnnouncements };
};
