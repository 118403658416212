import type { FC } from 'react';
import React from 'react';

import { files } from '../../../../src/components/common/SvgFlags/index';

const SVG_FLAGS_PROPS = {
  width: `${26 / 16}rem`,
  height: `${18 / 16}rem`,
  viewBox: [0, 0, 26, 18]
};
const flags = files.byId;
interface FlagProps {
  id: ValueOf<keyof typeof flags>;
}

export const Flag: FC<FlagProps> = ({ id }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    preserveAspectRatio="xMinYMin"
    width={SVG_FLAGS_PROPS.width}
    height={SVG_FLAGS_PROPS.height}
    viewBox={SVG_FLAGS_PROPS.viewBox.join(' ')}
  >
    <use xlinkHref={`#img-${id}`} />
  </svg>
);
