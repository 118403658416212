import type {
  FC, ComponentPropsWithRef, PropsWithChildren, ReactNode
} from 'react';
import React from 'react';
import classNames from 'classnames';

import styles from './Linear.module.scss';

export interface LinearProps extends PropsWithChildren<ComponentPropsWithRef<'div'>> {
  variant?: 'contained' | 'outlined' | 'text';
  startIcon?: ReactNode;
  iconSize?: 'small' | 'large';
  onClick?: () => void;
  children?: string;
}

export const Linear: FC<LinearProps> = ({
  variant = 'contained',
  startIcon,
  iconSize = 'small',
  onClick,
  children,
  id,
  className,
  ...props
}) => {
  const classes = classNames(className, styles[variant], { [styles.clickable]: onClick });

  return (
    <div
      id={id}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={(event) => {
        if (onClick && event.key === 'Enter') onClick();
      }}
      className={classes}
      {...props}
    >
      <div className={styles.inner}>
        {!!startIcon && (
          <span
            className={classNames(styles.icon, {
              [styles.icon_size_small]: iconSize === 'small',
              [styles.icon_size_large]: iconSize === 'large'
            })}
          >
            {startIcon}
          </span>
        )}
        {!!children && (
          <span className={styles.title}>
            {children}
          </span>
        )}
      </div>
    </div>
  );
};
