import type { FC } from 'react';
import React from 'react';

import styles from '../../../fields/selects/styles/Select.module.scss';

interface LabelProps {
  label: string;
}

export const Label: FC<LabelProps> = ({ label }) => (
  <label htmlFor={Math.random().toString()} className={styles.label}>
    {label}
  </label>
);
