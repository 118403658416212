import { resolveNewInfoItem } from '@utils/helpers/calculator/resolveNewInfoItem';
import { convertToMoney, multiplyByHundred, validateAmountLimit } from '@utils/helpers';
import type { CalculatorFormik, CalculatorValues } from '@components/calculator/hooks/useCalculatorFormik';
import type { AnnouncementsRequest, TariffsInfoRequest, TariffsRequest } from '@components/calculator/hooks/useCalculatorRequests';

export interface ChangeSendingCurrencyCalculatorValues extends CalculatorValues {
  sendingCountry: Country;
  receivingCountry: Country;
  sendingCurrency: Currency;
  receivingCurrency: Currency;
}
export type ChangeSendingCurrencyCalculatorFormik = CalculatorFormik<ChangeSendingCurrencyCalculatorValues>;

interface ChangeSendingCurrencyParams {
  formik: ChangeSendingCurrencyCalculatorFormik;
  tariffsInfo: TariffsInfoRequest;
  tariffs: TariffsRequest;
  announcements: AnnouncementsRequest;
  setCurrentInfoItem: (infoItem: TransferTariffInfo) => void;
}

export const changeSendingCurrency = async (
  newSendingCurrency: Currency,
  {
    formik,
    tariffsInfo,
    tariffs,
    announcements,
    setCurrentInfoItem
  }: ChangeSendingCurrencyParams
) => {
  const oldSendingCurrency = formik.values.sendingCurrency;
  const oldReceivingCurrency = formik.values.receivingCurrency;
  if (newSendingCurrency.id === oldSendingCurrency.id) return;

  await formik.setErrors({});
  await formik.setTouched({});

  const { newInfoItem } = resolveNewInfoItem(
    tariffsInfo.data,
    newSendingCurrency.id,
    oldReceivingCurrency.id
  );
  await formik.setFieldValue('sendingCurrency', newInfoItem.sendingCurrency);
  await formik.setFieldValue('receivingCurrency', newInfoItem.receivingCurrency);
  await formik.setFieldTouched('sendingCurrency', true);
  await formik.setFieldTouched('receivingCurrency', true);

  await announcements.mutate({
    sendingCountryId: formik.values.sendingCountry.id,
    receivingCountryId: formik.values.receivingCountry.id,
    receivingCurrencyId: newInfoItem.receivingCurrency.id,
    process: 'sendingTransfer',
    event: 'directionSelected'
  });

  const isSendingAmountValid = !!formik.values.sendingAmount && validateAmountLimit({
    amount: multiplyByHundred(+formik.values.sendingAmount),
    amountKey: 'sendingAmount',
    currentInfoItem: newInfoItem
  });
  if (!isSendingAmountValid) {
    setCurrentInfoItem(newInfoItem);
    if (formik.values.sendingAmount) await formik.validateForm();
    return;
  }

  const tariffsResponse = await tariffs.mutate({
    sendingCountryId: formik.values.sendingCountry.id,
    receivingCountryId: formik.values.receivingCountry.id,
    sendingCurrencyId: newInfoItem.sendingCurrency.id,
    receivingCurrencyId: newInfoItem.receivingCurrency.id,
    sendingAmount: multiplyByHundred(+formik.values.sendingAmount),
    paymentMethod: 'debitCard',
    receivingMethod: 'cash',
    paidNotificationEnabled: formik.values.paidNotification
  });
  if (!tariffsResponse.success) {
    await formik.setFieldValue('sendingCurrency', oldSendingCurrency);
    await formik.setFieldValue('receivingCurrency', oldReceivingCurrency);
    return;
  }
  setCurrentInfoItem(newInfoItem);
  // ✅ important:
  // validate form for delete previous errors
  const [usedTariff] = tariffsResponse.data;
  await formik.setFieldValue('sendingAmount', convertToMoney(usedTariff.sendingAmount).toString());
  await formik.setFieldValue('receivingAmount', convertToMoney(usedTariff.receivingAmount).toString());
  await formik.setFieldTouched('sendingAmount', true);
  await formik.setFieldTouched('receivingAmount', true);
  await formik.validateForm();
};
